import { createSlice } from '@reduxjs/toolkit';
import axios from '@util/axios';

import { fetchProfile, removeFeedbackFromQueue } from './profile';

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    convenience: 0,
    speed: 0,
    recommend: 0,
    comment: '',
    serviceName: '',
    relevance: 0,
    isRateService: false,
    isRateLate: false,
  },
  reducers: {
    setFeedback(state, action) {
      state.subscription = action.payload;
    },
    setConvenience(state, action) {
      state.convenience = action.payload;
    },
    setSpeed(state, action) {
      state.speed = action.payload;
    },
    setRecommend(state, action) {
      state.recommend = action.payload;
    },
    setComment(state, action) {
      state.comment = action.payload;
    },
    setServiceName(state, action) {
      state.serviceName = action.payload;
    },
    setRelevance(state, action) {
      state.relevance = action.payload;
    },
    setIsRateService(state, action) {
      state.isRateService = action.payload;
    },
    setIsRateLate(state, action) {
      state.isRateLate = action.payload;
    },
    setDefaultRating(state) {
      state.convenience = 0;
      state.speed = 0;
      state.recommend = 0;
      state.comment = '';
    },
    setDefaultRatingService(state) {
      state.serviceName = '';
      state.relevance = 0;
      state.isRateService = false;
    },
  },
});

export const sendFeedback = (data) => async (dispatch) => {
  const response = await axios.post(`/feedback`, data);

  if (response.data.success) {
    dispatch(feedbackSlice.actions.setDefaultRating());
    dispatch(fetchProfile());
    if (data.serviceName === 'system') {
      dispatch(removeFeedbackFromQueue('system-initiatives'));
    } else if (data.serviceName === 'hrsm') {
      dispatch(removeFeedbackFromQueue('hrsm'));
    } else if (data.serviceName === 'club') {
      localStorage.setItem('clubIsRated', 'true');
    }
  }
};

export default feedbackSlice;
