import { useMediaQuery } from '@mui/material';

import { Icon } from 'components/core-ui/Icon/Icon';

import styles from './Switcher.module.scss';

export const RegisterSwitcher = ({ step }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1024px)');

  const setStepIconName = (currentStep) => {
    if (currentStep === step) {
      return !isMobile ? 'stepActive' : 'stepActiveMobile';
    } else if (currentStep < step) {
      return !isMobile ? 'stepDone' : 'stepInactiveMobile';
    } else {
      return 'stepInactive';
    }
  };

  const setLineSize = () => {
    if (isMobile) return 'medium-big';
    if (isTablet) return 'big';

    return 'medium-big';
  };

  return (
    <div className={styles._}>
      <Icon name={setStepIconName(1)} size={!isMobile ? 'medium-big' : 'twenty'} />
      <Icon name='line' size={setLineSize()} />
      <Icon name={setStepIconName(2)} size={!isMobile ? 'medium-big' : 'twenty'} />
      <Icon name='line' size={setLineSize()} />
      <Icon name={setStepIconName(3)} size={!isMobile ? 'medium-big' : 'twenty'} />
      <Icon name='line' size={setLineSize()} />
      <Icon name={setStepIconName(4)} size={!isMobile ? 'medium-big' : 'twenty'} />
      <Icon name='line' size={setLineSize()} />
      <Icon name={setStepIconName(5)} size={!isMobile ? 'medium-big' : 'twenty'} />
    </div>
  );
};
