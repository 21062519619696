import { clsx } from 'clsx';

import { selectorButtons } from 'features/verification/utils/selectorButtons';

import { Button } from 'components/core-ui/Button/Button';

import styles from './CallSelector.module.scss';

export const CallSelector = (props) => {
  const { callType, telegramValue, changeCallType, setTelegramField } = props;

  return (
    <div
      className={clsx(styles.container, {
        [styles.container_withField]: callType.id === 'telegram',
      })}
    >
      <h1 className={styles.container_title}>Как с вами связаться?</h1>

      <div className={styles.controller}>
        {selectorButtons &&
          selectorButtons.map((buttonItem) => (
            <Button
              key={buttonItem.id}
              className={clsx(styles.selector, {
                [styles.selector_active]: buttonItem.id === callType.id,
              })}
              contentClassName={styles.selector_content}
              icon={buttonItem.id !== callType.id ? buttonItem.iconName : buttonItem.activeIconName}
              onClick={() => changeCallType({ id: buttonItem.id, type: buttonItem.type })}
            >
              {buttonItem.title}
            </Button>
          ))}
      </div>

      {callType.id === 'telegram' && (
        <div className={styles.field}>
          <div className={styles.telegram}>
            <span className={styles.telegram_text}>tg.me/@</span>
            <input
              className={styles.telegram_input}
              onChange={(e) => {
                if (e.target.value.match(/^[a-zA-Z0-9_]*$/g)) setTelegramField(e.target.value);
              }}
              value={telegramValue}
              type='text'
            />
          </div>
          <span className={styles.field_hint}>Укажите ваш ник в Telegram</span>
        </div>
      )}
    </div>
  );
};
