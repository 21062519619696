import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

export default Object.assign(moment, {
  format(val) {
    return moment(val).format('HH:mm:ss DD MMMM YYYY');
  },

  getDates(year, month) {
    const dates = [];

    let date = this.monthStart(year, month).startOf('week'),
      lastDay = this.monthStart(year, month).endOf('month').endOf('week');

    while (date.format('YYYY-MM-DD') !== lastDay.format('YYYY-MM-DD')) {
      addDay();
      date = date.add(1, 'day');
    }

    addDay();

    return dates;

    function addDay() {
      dates.push({
        current: date.month() + 1 === month,
        date: date.date(),
        year: date.year(),
        month: date.month() + 1,
        weekday: date.day() + 1,
      });
    }
  },

  getMonthTitle(year, month) {
    return moment(`${year}-${month}`, 'YYYY-MM').format('MMMM');
  },

  formatDate(day) {
    const month = (day.month + '').padStart(2, '0'),
      date = (day.date + '').padStart(2, '0');
    return `${day.year}-${month}-${date}`;
  },

  parseDate(value) {
    return moment(value, 'YYYY-MM-DD');
  },

  monthStart(year, month) {
    return moment({ year, month: month - 1, date: 1 });
  },

  isDateAvailable(start, finish, year, month, day) {
    const startValue = start.toDate().valueOf(),
      finishValue = finish.toDate().valueOf(),
      dateValue = moment({ year, month: month + 1, date: day });
    return dateValue >= startValue && dateValue <= finishValue;
  },

  getStartDate() {
    return moment({ year: 1900, month: 1, date: 1 });
  },

  getFinishDate() {
    return moment({ year: 2100, month: 11, date: 31 });
  },

  getYears() {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > currentYear - 50; i--) {
      years.push(i);
    }
    return years;
  },

  formatRemoveDate(d) {
    const date = moment(d).add(30, 'days');
    return date.format('HH:MM DD MMMM YYYY');
  },

  relativeDate(datetime) {
    const d = moment(datetime);
    if (d.isSame(new Date(), 'day')) {
      return d.format('HH:mm');
    }
    if (d.isSame(new Date(), 'week')) {
      return d.format('dd');
    }
    return d.format('DD.MM.YYYY');
  },

  formatDay(d) {
    const date = moment(d);
    if (date.isSame(new Date(), 'day')) {
      return 'Сегодня';
    }
    return date.format('D MMMM YYYY года');
  },

  formatWeekday(d) {
    const date = moment(d);
    if (date.isValid()) {
      return date.format('DD MMMM, dddd');
    } else {
      return false;
    }
  },

  getMonthTitle(n) {
    return [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ][+n];
  },
});
