import { createSlice } from '@reduxjs/toolkit';

import axios from 'util/axios';
import authSlice from './auth';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    jobFields: [],
    skills: [],
    profile: null,
    requests: null,
    waitingFeedbackQueue: null,
    updatedRequestsCount: 0,
    initialCommunities: null,
    initialProfile: null,
    isShowVerifiedModal: false,
    oldProfilePhone: '',
  },
  reducers: {
    setJobFields: (state, { payload }) => {
      state.jobFields = payload;
    },
    setSkills: (state, { payload }) => {
      state.skills = payload;
    },
    setProfile: (state, { payload }) => {
      state.profile = payload;
    },
    setProfileSkills: (state, { payload }) => {
      state = state.profile.skills.push(payload);
    },
    setProfileSkillsLoading: (state) => {
      state.isLoading = true;
    },
    setProfileSkillsLoadingSuccess: (state, { payload }) => {
      Object.assign(state, state);
      state.isLoading = false;
      state = {
        ...state,
        profile: { ...state.profile, skills: [...state.profile.skills, payload] },
      };
    },
    setProfileSkillsLoadingError: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    setRequests: (state, { payload }) => {
      state.requests = payload;
    },
    setFeedbackQueue: (state, { payload }) => {
      state.profile.feedbackQueue = payload;
    },
    setFeedbackSent: (state, { payload }) => {
      state.profile.feedbackSent = payload;
    },
    setWaitingFeedbackQueue: (state, { payload }) => {
      state.waitingFeedbackQueue = payload;
    },
    setUpdatedRequestsCount: (state, { payload }) => {
      state.updatedRequestsCount = payload;
    },
    setInitialCommunities: (state, { payload }) => {
      state.initialCommunities = payload;
    },
    changeBusinessCommunity: (state, { payload }) => {
      state.profile = { ...state.profile, communities: payload };
    },
    setInitialProfile: (state, { payload }) => {
      state.initialProfile = payload;
    },
    setIsShowVerifiedModal: (state, { payload }) => {
      state.isShowVerifiedModal = payload;
    },
    setOldProfilePhone: (state, { payload }) => {
      state.oldProfilePhone = payload;
    },
  },
});

export const resetRequestsUpdated = () => async (dispatch) => {
  await axios.get('/profile/reset-requests-updated');
  dispatch(fetchUpdatedRequestsCount());
};

export const fetchUpdatedRequestsCount = () => async (dispatch) => {
  const response = await axios.get('/profile/updated-requests-count');
  dispatch(profileSlice.actions.setUpdatedRequestsCount(response.data));
};

export const fetchFeedbackQueue = () => async (dispatch) => {
  const response = await axios.get(`/feedback/queue`);
  dispatch(profileSlice.actions.setWaitingFeedbackQueue(null));
  dispatch(profileSlice.actions.setFeedbackQueue(response.data.feedbackQueue));
  dispatch(profileSlice.actions.setFeedbackSent(response.data.feedbackSent));
};

export const addFeedbackToQueue = (serviceName, update) => async (dispatch) => {
  const response = await axios.post(`/feedback`, {
    serviceName,
  });
  if (update) {
    dispatch(profileSlice.actions.setFeedbackQueue(response.data.feedbackQueue));
    dispatch(profileSlice.actions.setFeedbackSent(response.data.feedbackSent));
  } else {
    dispatch(profileSlice.actions.setWaitingFeedbackQueue(serviceName));
  }
};

export const removeFeedbackFromQueue = (serviceName) => async (dispatch) => {
  const response = await axios.delete(`/feedback/queue`, {
    params: {
      serviceName,
    },
  });
  dispatch(profileSlice.actions.setFeedbackQueue(response.data.feedbackQueue));
  dispatch(profileSlice.actions.setFeedbackSent(response.data.feedbackSent));
};

export const sendFeedback = (data) => async () => {
  await axios.post(`/feedback`, data);
};

export const fetchRequests = () => async (dispatch) => {
  const response = await axios.get('/profile/requests');
  dispatch(profileSlice.actions.setRequests(response.data));
};

export const uploadImage = (blob) => async (dispatch) => {
  const formData = new FormData();
  formData.append('picture', blob, 'avatar.jpg');
  const response = await axios.post('/profile/upload-picture', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const picture = response.data;
  dispatch(authSlice.actions.setPicture(picture));
};

export const removeAvatar = () => async (dispatch) => {
  const response = await axios.delete('/profile/remove-picture');
  dispatch(authSlice.actions.setUser(response.data));
};

export const fetchProfile = () => async (dispatch) => {
  const response = await axios.get('/profile/current/');
  dispatch(profileSlice.actions.setProfile(response.data.profile));
  dispatch(profileSlice.actions.setInitialCommunities(response.data.profile.communities));
  dispatch(profileSlice.actions.setInitialProfile(response.data.profile));
};

export const loadJobFields = () => async (dispatch) => {
  const response = await axios.get('/profile/job-fields/');
  dispatch(profileSlice.actions.setJobFields(response.data));
};

export const loadSkills = () => async (dispatch) => {
  const response = await axios.get('/profile/skills/');
  dispatch(profileSlice.actions.setSkills(response.data));
};

export const updateProfileInfo = (data) => async (dispatch) => {
  const response = await axios.put('/profile/update-info/', data);
  dispatch(profileSlice.actions.setProfile(response.data.profile));
  dispatch(fetchProfile());
};

export const updateProfile = (data) => async (dispatch) => {
  const response = await axios.put('/profile/update/', {
    profile: data,
  });
  dispatch(profileSlice.actions.setProfile(response.data.profile));
};

export const setCompleted = () => async (dispatch) => {
  const response = await axios.put(`/profile/set-completed/`);
  dispatch(authSlice.actions.setUser(response.data.user));
};

export const updateEmail = (newEmail) => async (dispatch) => {
  const response = await axios.put('/profile/start-email-changing/', {
    newEmail: newEmail,
  });
  dispatch(profileSlice.actions.setProfile(response.data.profile));
};

export const updateProfileSkills = (data) => async (dispatch) => {
  try {
    dispatch(profileSlice.actions.setProfileSkillsLoading());
    const response = await axios.put('/profile/update-skills/', data);
    if (response.data.success === false) {
      dispatch(
        profileSlice.actions.setProfileSkillsLoadingError(JSON.stringify(response.data.errors)),
      );
    } else {
      dispatch(profileSlice.actions.setProfileSkillsLoadingSuccess(response.data.profile.skills));
    }
  } catch (error) {
    dispatch(profileSlice.actions.setProfileSkillsLoadingError(error.message));
  }
};

export default profileSlice;
