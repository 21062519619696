import Logo from '@common/Logo';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import commonSlice from 'store/common';

import styles from './GlobalLoader.module.scss';

const GlobalLoader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(commonSlice.actions.setGlobalLoading(false));
    }, 500);
  }, []);

  return (
    <div className={styles.globalLoader}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.progress}>
        <div className={styles.line}></div>
      </div>
    </div>
  );
};

export default GlobalLoader;
