import { useEffect, useRef, useState, useCallback } from 'react';
import axios from 'util/axios';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useMediaQuery } from '@mui/material';
import { CSSTransition } from 'react-transition-group';

import { useBusinessCommunities } from 'hooks/useBusinessCommunities';
import { useUsers } from 'hooks/useUsers';

import { DrawerBlock } from 'components/core-ui/DrawerBlock/DrawerBlock';
import { Icon } from 'components/core-ui/Icon/Icon';
import {
  CustomDropdownIndication,
  CustomValueContainer,
  CustomControl,
} from 'components/core-ui/Select/components/Components';
import { TextInput } from 'components/core-ui/TextInput/TextInput';
import { CommunityModal } from './CommunityModal/CommunityModal';
import { DropdownIndicator } from '../DropdownIndicator/DropdownIndicator';

import {
  redesignSelectMobileStyles,
  redesignSelectTabletStyles,
} from 'components/core-ui/select-styles-mobile';
import { redesignSelectSingleStyles } from 'components/core-ui/select-styles';
import styles from './NewCompletionCommunity.module.scss';

const CompletionCommunities = (props) => {
  const { value, selectedCommunities, indexComm, onChange, onRemove, onRemoveEmptyComm } = props;

  // TODO: исправить крестик

  const user = useUsers();
  const inputRef = useRef();
  const drawerBlockRef = useRef(null);
  const isTablet = useMediaQuery('(max-width: 1024px)');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const businessCommunities = useBusinessCommunities();
  const [isDrawerBlock, setIsDrawerBlock] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isNewCommunity, setIsNewCommunity] = useState(false);
  const [isEditCommunity, setIsEditCommunity] = useState(false);
  const [isOpenMenuOnClick, setIsOpenMenuOnClick] = useState(true);
  const [isUserTypeActivity, setIsUserTypeActivity] = useState(false);
  const [label, setLabel] = useState('');
  const [placeholderMobile, setPlaceholderMobile] = useState('');
  const [newCommunity, setNewCommunity] = useState({
    title: '',
    url: '',
    __isNew__: true,
  });

  const formatOptionLabel = () => (
    <div className={styles.optionLabel}>
      {isUserTypeActivity ? (
        <>
          <div>Такого сообщества еще нет в списке</div>
          <span>добавить</span>
        </>
      ) : (
        <>
          {!isMobile ? (
            <div>
              Если вашего сообщества нет в списке, его можно <span>добавить</span>
            </div>
          ) : (
            <div>
              Если вашего сообщества нет в списке, <br /> его можно <span>добавить</span>
            </div>
          )}
        </>
      )}
    </div>
  );

  const clearCommunityValue = () => {
    onChange('');
  };

  const communitiesChanged = (community) => {
    if (!community) {
      if (value?.id && value?.id > 0) {
        onRemove(value?.id);
      } else if (value?.title && value?.title !== '') {
        clearCommunityValue();
        setIsOpenMenuOnClick(true);
      } else {
        onRemoveEmptyComm();
      }
    }

    if (community) {
      onChange(
        !community?.url
          ? {
              id: community?.id,
              title: community?.label || community?.title,
              commIndex: indexComm,
              status: 'new',
            }
          : community,
      );
    }

    isTablet && setIsDrawerBlock(false);
  };

  const filterComm = (data = []) => {
    let filteredData = data;

    selectedCommunities.forEach((item) => {
      filteredData = filteredData.filter((comm) => {
        return comm.id !== item.id;
      });
    });

    return filteredData;
  };

  const loadDefaultOptions = () => {
    const filteredData = filterComm(businessCommunities);
    const customOption = { value: 'no_option', label: formatOptionLabel(), __isNew__: true };
    const filteredDataWithCustomOption = filteredData.map(communityToOption).concat(customOption);

    setDefaultOptions(filteredDataWithCustomOption);
  };

  const loadOptions = async (text) => {
    const { data } = await axios.get(`/profile/communities/filter/?text=${text}`);
    const filteredData = filterComm(data);
    return filteredData.map(communityToOption);
  };

  const communityToOption = (community) => {
    return {
      id: community.id || community.title || '',
      value: community.id || community.title || community.label || '',
      label: community.title || '',
    };
  };

  const createNewCommunity = (value) => {
    setIsNewCommunity(true);
    setNewCommunity({ ...newCommunity, title: value, __isNew__: true });
  };

  const addCustomCommunity = () => {
    onChange(newCommunity);
    createNewCommunity(newCommunity); // TODO: FIX
    setNewCommunity(newCommunity);
    setIsNewCommunity(false);
    setIsEditCommunity(false);
    setIsOpenMenuOnClick(false);
  };

  const changeModalTitleCommunity = useCallback(
    (event) => {
      setNewCommunity({ ...newCommunity, title: event.target.value });
    },
    [newCommunity],
  );

  const changeModalUrlCommunity = useCallback(
    (event) => {
      setNewCommunity({ ...newCommunity, url: event.target.value });
    },
    [newCommunity],
  );

  const onDeleteCommunity = () => {
    communitiesChanged(null);
  };

  const closeDrawerBlock = () => {
    const closeDrawerBlock = new CustomEvent('closeDrawerBlock');
    document.dispatchEvent(closeDrawerBlock);
    setIsDrawerBlock(false);
  };

  const showEditModalCommunity = (communityProps) => {
    if (isMobile ?? !Number(communityProps.title)) {
      setIsEditCommunity(true);
    }

    const { selectProps } = communityProps;
    if (selectProps && selectProps.value && !Number(selectProps.value.id)) {
      setIsEditCommunity(true);
    }
  };

  const setMobileOrTabletStyles = () => {
    if (isMobile) return redesignSelectMobileStyles;
    if (isTablet) return redesignSelectTabletStyles;
  };

  const selectPropsMobile = {
    isClearable: indexComm === 0,
    isSearchable: true,
    loadOptions: loadOptions,
    defaultOptions: defaultOptions,
    value: value?.title || value?.label ? communityToOption(value) : '',
    onChange: communitiesChanged,
    className: 'react-select react-select--capital',
    classNamePrefix: 'react-select react-select--capital',
    defaultMenuIsOpen: true,
  };

  const valueToString = (val) => {
    return val.title || val.label || '';
  };

  useEffect(() => {
    if (businessCommunities) loadDefaultOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessCommunities]);

  useEffect(() => {
    if (isMobile) {
      setLabel('');
      setPlaceholderMobile('Резидентство в бизнес-сообществах');
    }

    if (isTablet) {
      setLabel('Резидентство в бизнес-сообществах');
      setPlaceholderMobile('');
    }
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (value) setIsUserTypeActivity(true);
  }, [value]);

  if (!user) return null;

  return (
    <>
      {isTablet ? (
        <div className={styles.wrapper}>
          <div className={styles.communityItem}>
            <div className={styles.communityItem_container}>
              <div
                className={styles.communityItem_container_input}
                onClick={() => {
                  if (value?.__isNew__) showEditModalCommunity(value);
                  else setIsDrawerBlock(true);
                }}
              >
                <TextInput
                  label={label}
                  placeholderMobile={placeholderMobile}
                  placeholder=' '
                  type='text'
                  value={value ? valueToString(value) : ''}
                  autoComplete='off'
                  disabled
                  classN
                />
              </div>

              <DropdownIndicator
                index={indexComm}
                hasValue={value && value.title}
                onDeleteCommunity={onDeleteCommunity}
              />
            </div>

            {indexComm === 0 ? null : (
              <div
                className={styles.communityItem_delete}
                onClick={() => onRemoveEmptyComm(indexComm)}
              >
                <div>
                  <Icon name='remove' />
                </div>
              </div>
            )}
          </div>

          <CSSTransition in={isDrawerBlock} nodeRef={drawerBlockRef} timeout={250} unmountOnExit>
            <div ref={drawerBlockRef}>
              <DrawerBlock
                title='Резидентство в бизнес-сообществах'
                closeButton
                onClose={() => setIsDrawerBlock(false)}
                isRedesign
              >
                <div>
                  <AsyncCreatableSelect
                    {...selectPropsMobile}
                    ref={inputRef}
                    components={{
                      Control: CustomControl,
                      IndicatorsContainer: () => null,
                      LoadingIndicator: null,
                    }}
                    styles={{
                      ...setMobileOrTabletStyles(),
                      indicatorsContainer: () => ({
                        height: '32px',
                        width: '32px',
                      }),
                    }}
                    onCreateOption={(val) => {
                      createNewCommunity(val);
                      closeDrawerBlock();
                    }}
                    cacheOptions={false}
                    formatCreateLabel={formatOptionLabel}
                    onChange={(option) => {
                      if (option && option.value === 'no_option') {
                        createNewCommunity('');
                        closeDrawerBlock();
                      } else communitiesChanged(option);
                      setIsUserTypeActivity(false);
                    }}
                    placeholder='поиск'
                    hideSelectedOptions
                    isLoading={false}
                    backspaceRemovesValue={false}
                  />

                  <div className={styles.clearValue} onClick={clearCommunityValue}>
                    <Icon name='closeCommunityMobile' size='16' />
                  </div>
                </div>
              </DrawerBlock>
            </div>
          </CSSTransition>
        </div>
      ) : (
        <>
          <div className={styles.communityItem}>
            <div className={styles.communityItem_container}>
              <AsyncCreatableSelect
                components={{
                  ValueContainer: (props) => {
                    return (
                      <div style={{ width: '100%' }} onClick={() => showEditModalCommunity(props)}>
                        <CustomValueContainer {...props} />
                      </div>
                    );
                  },
                  IndicatorsContainer: CustomDropdownIndication,
                  DropdownIndicator: null,
                  LoadingIndicator: null,
                  ClearIndicator: null,
                }}
                openMenuOnClick={isOpenMenuOnClick}
                menuPlacement='bottom'
                loadOptions={loadOptions}
                onCreateOption={createNewCommunity}
                defaultOptions={defaultOptions}
                styles={redesignSelectSingleStyles}
                allowCreateWhileLoading={true}
                placeholder='Резидентство в бизнес-сообществах'
                formatCreateLabel={formatOptionLabel}
                isSearchable
                backspaceRemovesValue={false}
                hideSelectedOptions
                onChange={(option) => {
                  if (option && option.value === 'no_option') createNewCommunity('');
                  else communitiesChanged(option);
                  setIsUserTypeActivity(false);
                }}
                value={value?.title || value?.label ? communityToOption(value) : ''}
              />

              {indexComm !== 0 && (
                <div
                  className={styles.communityItem_delete}
                  onClick={() => onRemoveEmptyComm(indexComm)}
                >
                  <div>
                    <Icon name='remove' />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {isNewCommunity && (
        <CommunityModal
          title='Добавить бизнес-сообщество'
          newCommunity={newCommunity}
          addCustomCommunity={addCustomCommunity}
          changeModalTitleCommunity={changeModalTitleCommunity}
          changeModalUrlCommunity={changeModalUrlCommunity}
          closeModal={() => {
            setIsNewCommunity(false);
          }}
        />
      )}

      {isEditCommunity && (
        <CommunityModal
          title='Редактировать бизнес-сообщество'
          newCommunity={newCommunity}
          addCustomCommunity={addCustomCommunity}
          changeModalTitleCommunity={changeModalTitleCommunity}
          changeModalUrlCommunity={changeModalUrlCommunity}
          closeModal={() => {
            setIsEditCommunity(false);
          }}
        />
      )}
    </>
  );
};

export default CompletionCommunities;
