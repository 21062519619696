import { Social } from '@ui/Social/Social';
import styles from './LeaderAuthButton.module.scss';

const LeaderAuthButton = ({ isRedesign }) => {
  return (
    <div className={styles.leaderButton}>
      <Social name='leader' alwaysActive={false} isRedesign={isRedesign} />
    </div>
  );
};

export default LeaderAuthButton;
