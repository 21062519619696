import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Social } from '@ui/Social/Social';
import { signInWithVk } from '@store/auth';

const VkAuthButton = ({ onDone, isLogin, isRedesign }) => {
  const dispatch = useDispatch();
  const [childWindow, setChildWindow] = useState(null);

  const buttonClicked = () => {
    const url =
      `https://oauth.vk.com/authorize?` +
      `client_id=${process.env.REACT_APP_VK_APP_ID}` +
      `&scope=email,offline` +
      `&redirect_uri=${process.env.REACT_APP_API_URL}auth/vk/redirect` +
      `&display=popup&response_type=token`;
    setChildWindow(
      window.open(
        url,
        '_blank',
        'location=yes,height=570,width=520,scrollbars=yes,status=yes,top=200,left=720',
      ),
    );
  };

  const messageCallback = useCallback((ev) => {
    if (typeof ev.data !== 'string' || !childWindow) return;
    childWindow.close();
    dispatch(signInWithVk({ url: ev.data, isLogin }));
    setChildWindow(null);
    onDone();
  });

  useEffect(() => {
    window.addEventListener('message', messageCallback, false);
    return () => {
      window.removeEventListener('message', messageCallback);
    };
  }, [messageCallback]);

  return (
    <div>
      <Social name='vk' onClick={buttonClicked} isRedesign={isRedesign} />
    </div>
  );
};

export default VkAuthButton;
