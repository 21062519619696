import { useEffect, useState } from 'react';
import { clsx } from 'clsx';

import calendarSettings from 'settings/calendar.yaml';

import { Button } from 'components/core-ui/Button/Button';

import styles from './NewCalendarTime.module.scss';

export const NewCalendarTime = (props) => {
  const { currentDate, changeTime } = props;

  const [times, setTimes] = useState([]);
  const [correctTimes, setCorrectTimes] = useState([]);
  const [activeButton, setActiveButton] = useState(0);

  const getCorrectTime = (dateTimeValue, itemLabelValue) => {
    const dateTime = new Date(dateTimeValue).toLocaleString().split(',');
    const currentHour = new Date(dateTimeValue).getHours();
    const itemLabel = itemLabelValue.split(' – ');
    const [hourFrom] = itemLabel[0].split(':');
    const currentDateTime = new Date().toLocaleString().split(',');

    if (dateTime[0] === currentDateTime[0]) {
      return currentHour >= hourFrom;
    } else {
      return false;
    }
  };

  const getTimesWithDisabled = () => {
    const newTime = times.map((timeItem) => ({
      title: timeItem.title,
      items: timeItem.items.map((item) => ({
        value: item.value,
        label: item.label,
        isDisabled: getCorrectTime(currentDate.fullDate, item.label),
      })),
    }));

    setCorrectTimes(newTime);
  };

  const changeTimeAndSetActive = (timeValue) => {
    setActiveButton(timeValue);
    changeTime(timeValue);
  };

  useEffect(() => {
    if (calendarSettings) setTimes(calendarSettings.time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarSettings, calendarSettings.time]);

  useEffect(() => {
    if (currentDate) getTimesWithDisabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  return (
    <div className={styles.times}>
      <h1 className={styles.times_title}>В какое время вам удобно?</h1>

      <div className={styles.times_list}>
        {correctTimes &&
          correctTimes.map((timeItem) => (
            <div key={timeItem.title} className={styles.time}>
              <span className={styles.time_title}>{timeItem.title}</span>

              <div className={styles.time_controller}>
                {timeItem.items.map((item) => (
                  <Button
                    key={item.value}
                    className={clsx(styles.time_button, {
                      [styles.time_button_active]: item.value === activeButton,
                    })}
                    disabled={item.isDisabled}
                    onClick={() => {
                      changeTimeAndSetActive(item.value);
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
