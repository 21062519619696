import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { clsx } from 'clsx';

import { completeDateTimeManagerCall } from 'store/registration';

import { Button } from 'components/core-ui/Button/Button';
import { Modal } from 'components/core-ui/Modal/Modal';
import Loader from 'components/common/Loader';
import { CallSelector } from 'components/common/CallSelector/CallSelector';
import { NewCalendarTime } from 'components/common/calendar/NewCalendarTime/NewCalendarTime';

import styles from './ManagerCallStep.module.scss';

export const ManagerCallStep = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1024px)');
  const loading = useSelector((state) => state.common.loading);
  const [isShowDateModal, setIsShowDateModal] = useState(false);
  const [date, setDate] = useState('');
  const [currentDate, setCurrentDate] = useState({});
  const [futureDays, setFutureDays] = useState([]);
  const [correctDates, setCorrectDates] = useState([]);
  const [currentDay, setCurrentDay] = useState();
  const [time, setTime] = useState(0);
  const [callType, setCallType] = useState({ id: '', type: '' });
  const [telegramValue, setTelegramValue] = useState('');

  const getFutureDays = (dateValue) => {
    const datesArr = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(dateValue);
      date.setDate(date.getDate() + i);
      datesArr.push(date.toISOString());
    }

    setFutureDays(datesArr);
  };

  const getDays = (days) => {
    let months = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ];

    let daysOfWeek = [
      'воскресенье',
      'понедельник',
      'вторник',
      'среда',
      'четверг',
      'пятница',
      'суббота',
    ];

    const newDays = days.map((dayItem) => ({
      fullDate: dayItem,
      dayOfMonth: new Date(dayItem).getDate(),
      month: months[new Date(dayItem).getMonth()],
      dayOfWeek: daysOfWeek[new Date(dayItem).getDay()],
    }));

    setCurrentDate(newDays[1]);
    setCorrectDates(newDays);
    setCurrentDay(newDays[1].dayOfMonth);
  };

  const changeTime = (timeValue) => {
    setTime(timeValue);
  };

  const changeCallType = (callTypeValue) => {
    setCallType(callTypeValue);
  };

  const changeTelegramValue = (value) => {
    setTelegramValue(value);
  };

  const submitVerifyManagerCall = () => {
    const dateForSubmit = new Date(currentDate.fullDate).toISOString().substring(0, 10);

    dispatch(
      completeDateTimeManagerCall(
        {
          date: dateForSubmit,
          time,
          method: callType.id,
          telegram: `@${telegramValue}`,
        },
        false,
      ),
    );
  };

  useEffect(() => {
    getFutureDays(new Date());
  }, []);

  useEffect(() => {
    if (date === '') setDate(new Date().toLocaleString());
  }, [date]);

  useEffect(() => {
    if (futureDays.length) getDays(futureDays);
  }, [futureDays]);

  if (loading) {
    return (
      <div className={styles.content_loader}>
        <Loader isRegister />
      </div>
    );
  }

  return (
    <div className={clsx(styles.content)}>
      <div className={styles.header}>
        <div className={styles.header_text}>
          <span className={styles.header_title}>Верификация аккаунта</span>

          <div className={styles.header_description}>
            <span>Почти закончили! Осталось подтвердить данные.</span>

            <span>
              Мы с вами создаём надежное сообщество предпринимателей. Для этого, наш менеджер
              знакомится с каждым участником платформы лично, в кратком неформальном звонке.
            </span>

            {isTablet ? (
              <span>
                Менеджер уточнит информацию и поможет заполнить ваш профиль. Разговор займёт не
                более 20 минут.
              </span>
            ) : (
              <span>
                Менеджер уточнит информацию и поможет заполнить ваш профиль. Разговор займёт <br />
                не более 20 минут.
              </span>
            )}

            <span>Воспользоваться сервисами можно до звонка менеджера.</span>
          </div>
        </div>

        <CallSelector
          callType={callType}
          telegramValue={telegramValue}
          setTelegramField={changeTelegramValue}
          changeCallType={changeCallType}
        />

        <NewCalendarTime currentDate={currentDate} changeTime={changeTime} />
      </div>

      <div className={styles.body}>
        <div className={styles.manager}>
          {!isMobile ? (
            <div className={styles.manager_title}>
              {`Менеджер Ирина позвонит вам ${callType.type}`}
            </div>
          ) : (
            <div className={styles.manager_title}>
              Менеджер Ирина позвонит вам <br />
              {` ${callType.type}`}
            </div>
          )}

          <div className={styles.dateAndTime}>
            <div className={styles.date}>
              <span
                className={styles.date_text}
              >{`${currentDate.dayOfMonth} ${currentDate.month}, ${currentDate.dayOfWeek}`}</span>
              <Button className={styles.date_change} onClick={() => setIsShowDateModal(true)}>
                Изменить дату
              </Button>
            </div>

            <span className={styles.time}>08:00 – 10:00</span>
          </div>
        </div>

        <div className={styles.body_complete}>
          <Button
            className={styles.body_completeBtn}
            onClick={submitVerifyManagerCall}
            disabled={time === 0 || callType.id === ''}
          >
            Завершить регистрацию
          </Button>
        </div>
      </div>

      {isShowDateModal && (
        <Modal
          onClose={() => setIsShowDateModal(false)}
          contentClassName={styles.dateContent}
          closeIconClassName={styles.dateClose}
        >
          <div className={styles.dateModal}>
            <div className={styles.dateModal_header}>Изменение даты звонка</div>

            <div className={styles.dateModal_body}>
              <div className={styles.dateSelector}>
                <span
                  className={styles.dateSelector_title}
                >{`${currentDate.dayOfMonth} ${currentDate.month}, ${currentDate.dayOfWeek}`}</span>

                <div className={styles.dateSelector_controller}>
                  {correctDates.length &&
                    correctDates.map((day) => (
                      <Button
                        key={day.dayOfMonth}
                        className={clsx(styles.dateSelector_controllerBtn, {
                          [styles.dateSelector_controllerBtn_active]: day.dayOfMonth === currentDay,
                        })}
                        onClick={() => {
                          setCurrentDate(day);
                          setCurrentDay(day.dayOfMonth);
                        }}
                      >
                        {`${day.dayOfMonth}`}
                      </Button>
                    ))}
                </div>
              </div>

              <Button className={styles.submit} onClick={() => setIsShowDateModal(false)}>
                Подтвердить
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
