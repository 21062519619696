import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import urls from 'settings/urls.yaml';

import authSlice, { logoutRedesign } from 'store/auth';
import profileSlice from 'store/profile';

import { SectionSlider } from 'components/common/sectionSlider/SectionSlider';

import styles from './RegisterStep.module.scss';

export const RegisterStep = ({ onAuthDone }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.header_title}>Регистрация</span>
          <span className={styles.header_hint}>Используйте социальные сети</span>
        </div>

        <SectionSlider onAuthDone={onAuthDone} isLogin={false} />
      </div>

      <div
        className={styles.link}
        onClick={() => {
          dispatch(logoutRedesign());
          dispatch(authSlice.actions.setUser(null));
          dispatch(profileSlice.actions.setProfile(null));
        }}
      >
        <span className={styles.link_has}>Уже есть аккаунт?</span>
        <Link className={styles.link_login} to={urls.landing.login}>
          Войти
        </Link>
      </div>
    </>
  );
};
