import { useMediaQuery } from '@mui/material';

import { Button } from 'components/core-ui/Button/Button';
import { TextInput } from 'components/core-ui/TextInput/TextInput';
import { Modal } from 'components/core-ui/Modal/Modal';

import styles from '../NewCompletionCommunity.module.scss';

export const CommunityModal = (props) => {
  const {
    title,
    newCommunity,
    addCustomCommunity,
    changeModalTitleCommunity,
    changeModalUrlCommunity,
    closeModal,
  } = props;

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Modal
      onClose={closeModal}
      contentClassName={styles.modalContent}
      closeIconClassName={styles.modalClose}
      isRedesign
    >
      <div className={styles.content}>
        <div className={styles.content_header}>
          <h2>{title}</h2>

          {isMobile ? (
            <span className={styles.content_description}>
              Эта информация будет видна после проверки модератора <br /> в вашем профиле
            </span>
          ) : (
            <span className={styles.content_description}>
              Эта информация будет видна после проверки модератора в вашем профиле
            </span>
          )}
        </div>

        <div className={styles.content_body}>
          <div className={styles.modalInput}>
            <TextInput
              classN
              label='Название'
              placeholder=' '
              type='text'
              value={newCommunity.title}
              onChange={(e) => {
                changeModalTitleCommunity(e);
              }}
              autoComplete='off'
              capital={true}
            />
          </div>

          <div className={styles.modalInput}>
            <TextInput
              classN
              label='Ссылка'
              placeholder=' '
              type='text'
              value={newCommunity.url}
              onChange={(e) => {
                changeModalUrlCommunity(e);
              }}
              autoComplete='off'
              capital={true}
            />
          </div>

          <div className={styles.footer}>
            <Button
              onClick={addCustomCommunity}
              disabled={!newCommunity.title}
              className={styles.footer_submit}
            >
              добавить
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
