import propTypes from 'prop-types';

import { Avatar } from '@ui/Avatar/Avatar';

import styles from './SelectUsersResult.module.scss';
import { stringToOption } from '@util/transform';
import { Icon } from '@ui/Icon/Icon';
import { useMediaQuery } from '@mui/material';

const SelectUsersResult = ({
  name,
  picture,
  position,
  city,
  hideClose,
  isSelected,
  openInvitation,
  onRemove,
  onClick,
  isShowAvatar = true,
}) => {
  const isTablet = useMediaQuery('(max-width:968px)');

  return (
    <div className={styles.result} onClick={onClick} data-selected={isSelected ? 'selected' : ''}>
      {isShowAvatar && (
        <div className={styles.avatar}>
          <Avatar url={picture} size='micro' />
        </div>
      )}
      <div className={styles.details}>
        <div className={openInvitation ? styles.openInviteUser : styles.name}>{name}</div>
        {city && (
          <div className={styles.position}>
            {position && `${position}, `} {stringToOption(city).label}
          </div>
        )}
      </div>
      <div className={styles.invitation}>
        {openInvitation &&
          (isTablet ? (
            <div className={styles.mobileInvite}>
              <Icon name='inviteUser' size='medium' />
            </div>
          ) : (
            <strong>Пригласить на платформу</strong>
          ))}
      </div>
      {isSelected && !hideClose && (
        <div className={styles.remove} onClick={onRemove}>
          <Icon name='close' />
        </div>
      )}
    </div>
  );
};

SelectUsersResult.defaultProps = {
  isSelected: false,
};

export default SelectUsersResult;
