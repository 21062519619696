import { useEffect, useState } from 'react';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL.split('/api/')[0];

export const UseCorrectAvatar = (url) => {
  const [correctURL, setCorrectUrl] = useState('');

  useEffect(() => {
    if (url.includes('https')) setCorrectUrl(url);
    else setCorrectUrl(`${REACT_APP_API_URL}${url}`);
  }, [url]);

  return correctURL;
};
