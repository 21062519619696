import { clsx } from 'clsx';

import styles from './Loader.module.scss';

const Loader = ({ className, isRegister }) => {
  return (
    <div
      className={clsx(styles.loader, {
        [styles.loader_noBackground]: className,
        [styles.loader_register]: isRegister,
      })}
    >
      <div className={styles.wrapper}>
        <div className={styles.spinnerBox}>
          <div className={styles.circleBorder}>
            <div className={styles.circleCore} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
