import { createSlice } from '@reduxjs/toolkit';

import axios from 'util/axios';

import { fetchProfile, fetchRequests } from './profile';

export const systemSlice = createSlice({
  name: 'system',
  initialState: {
    count: 0,
    step: -1,
    isFullSizeMenu: true,
  },
  reducers: {
    setCount: (state, { payload }) => {
      state.count = payload;
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    setIsFullSizeMenu: (state, { payload }) => {
      state.isFullSizeMenu = payload;
    },
  },
});

export const fetchRequestsCount = () => async (dispatch) => {
  const response = await axios.get('/system/requests-count/');
  dispatch(systemSlice.actions.setCount(+response.data));
};

export const sendSystemRequest = (data) => async (dispatch) => {
  const response = await axios.post('/system/request/', data);
  dispatch(systemSlice.actions.setCount(+response.data));
  dispatch(fetchProfile());
  dispatch(systemSlice.actions.setStep(1));
  dispatch(fetchRequests());
};

export default systemSlice;
