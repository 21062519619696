import PropTypes from 'prop-types';

import { Icon } from '@ui/Icon/Icon';

import styles from './Gallery.module.scss';

const Gallery = ({ onClick }) => {
  return (
    <div className={styles.gallery} onClick={onClick}>
      <Icon size='medium' name='photo'></Icon>
      <span className={styles.gallery_text}>выбрать из галереи</span>
    </div>
  );
};

Gallery.propTypes = {
  onClick: PropTypes.func,
};

export { Gallery };
