import { createSlice } from '@reduxjs/toolkit';
import axios from '@util/axios';
import moment from 'moment';
import { sortBy } from 'lodash';

import { formatEventDate } from 'util/formatEventDate';

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    events: null,
    googleEvents: null,
    calendarEvent: null,
    isShowCalendarEventCard: false,
  },
  reducers: {
    setCalendarEvents(state, action) {
      state.events = action.payload;
    },
    setGoogleEvents(state, action) {
      state.googleEvents = action.payload;
    },
    setCalendarEvent(state, action) {
      state.calendarEvent = action.payload;
    },
    setIsShowCalendarEventCard(state, action) {
      state.isShowCalendarEventCard = action.payload;
    },
  },
});

export const fetchCalendarEvents = (calendarId) => async (dispatch) => {
  // const timeMin = moment().subtract(7, 'days').toISOString();
  const response = await axios.get(`/calendar/${calendarId}/events`);

  if (response) {
    const sortedEvents = sortBy(
      response.data.events.map((item) => ({
        eventId: item.id,
        title: item.title,
        date: formatEventDate(moment(item.start), moment(item.finish)),
        isOnline: item.isOnline,
        city: item.city,
        startTime: +moment(item.start).toDate(),
        link: item.googleLinks && Object.values(item.googleLinks)[0],
      })),
      'startTime',
    );
    // .filter((event) => event.date < timeMin);

    dispatch(calendarSlice.actions.setCalendarEvents(sortedEvents));
  }
};

export const fetchGoogleEvents = (calendarId) => async (dispatch) => {
  const response = await axios.get(`/calendar/google/${calendarId}`);

  if (response) {
    let googleEvents = sortBy(
      response.data.items.map((item) => ({
        title: item.summary,
        date: formatEventDate(
          moment(item.start.date || item.start.dateTime),
          moment(item.end.date || item.end.dateTime),
        ),
        startTime: +moment(item.start.date || item.start.dateTime).toDate(),
        link: item.htmlLink,
      })),
      'startTime',
    ).filter((event) => !!event.date);

    dispatch(calendarSlice.actions.setGoogleEvents(googleEvents));
  }
};

export const fetchCalendarEvent = (eventId) => async (dispatch) => {
  const response = await axios.get(`/calendar/events/${eventId}`);

  if (response) {
    dispatch(calendarSlice.actions.setCalendarEvent(response.data));
  }
};

export default calendarSlice;
