export const selectorButtons = [
  {
    id: 'call',
    title: 'По телефону',
    type: 'по телефону',
    iconName: 'callPhone',
    activeIconName: 'callPhoneActive',
  },
  {
    id: 'whatsapp',
    title: 'Через WhatsApp',
    type: 'через WhatsApp',
    iconName: 'callWhatsApp',
    activeIconName: 'callWhatsAppActive',
  },
  {
    id: 'telegram',
    title: 'Через Telegram',
    type: 'через Telegram',
    iconName: 'callTg',
    activeIconName: 'callTgActive',
  },
];

export const verificationTypeOptions = [
  { id: 'call', value: 'по телефону', label: 'По телефону' },
  { id: 'whatsapp', value: 'через Whatsapp', label: 'Через Whatsapp' },
  { id: 'telegram', value: 'через Telegram', label: 'Через Telegram' },
];

export const verificationTimes = [
  { value: 8, label: '08:00 – 10:00' },
  { value: 10, label: '10:00 – 12:00' },
  { value: 12, label: '12:00 – 14:00' },
  { value: 14, label: '14:00 – 17:00' },
  { value: 17, label: '17:00 – 20:00' },
  { value: 20, label: '20:00 – 21:00' },
];
