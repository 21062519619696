const tagStyles = {
  background: '#0018a8',
  color: '#fff',
  display: 'flex',
  padding: `0 4px 0 4px !important`,
  borderRadius: '8px',
  margin: '2px 4px 2px 0px',
  height: '31px',
  alignItems: 'center',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '14px',
  letterSpacing: '0em',
  cursor: 'pointer',
  '& div[role="button"]': {
    height: '16px',
    padding: '0',
    width: '16px',
    borderRadius: '3px',
    transition: '0.25s background ease-in-out !important',
    '& svg path': {
      transition: '0.25s color ease-in-out !important',
      color: '#EFEFEF !important',
    },
    '&:hover,&:focus,&:active': {
      background: '#394ECA !important',
      '& svg path': {
        color: '#EFEFEF !important',
      },
    },
  },
  '& div[role="button"] svg': {
    width: '13px',
    height: '13px',
    margin: 'auto',
  },
  fontFamily: 'Roboto',
};

const selectStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles, state) => {
    return {
      ...styles,
      background: '#ffffff',
      border: `1px solid #3f4049`,
      borderColor: state.menuIsOpen ? '#0018a8 !important' : '#3f4049',
      transition: '0.25s border-color ease-in-out !important',
      borderRadius: '6px',
      padding: '2px 52px 2px 2px',
      width: '100%',
      boxShadow: 'unset',
      '&:hover, &:focus': {
        borderColor: '#0018a8',
      },
      '&:active': {
        borderColor: '#3f4049',
      },
      outline: 'none',
      fontStyle: 'normal',
      lineHeight: '110%',
      color: '#3f4049',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0em',
      minHeight: '40px',
      textTransform: 'lowercase',
      cursor: 'pointer',
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      zIndex: 10000000000000000,
      background: state.isFocused ? '#0018A8' : '#EFEFEF',
      color: state.isFocused ? '#fff' : '#3F4049',
      fontSize: '14px',
      lineHeight: '15px',
      padding: '13px 16px',
      '&:hover': {
        background: '#0018A8',
        color: '#fff',
      },
      cursor: 'pointer',
    };
  },
  menuList: (styles) => ({
    ...styles,
    background: '#EFEFEF',
  }),
  input: (styles) => ({
    ...styles,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0em',
    color: '#3F4049 !important',
  }),
  menu: (styles, state) => ({
    ...styles,
    background: '#EFEFEF',
    borderRadius: state.placement === 'top' ? '8px 8px 0 0' : '0px 0px 8px 8px',
    margin: 0,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0 5px',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    position: 'absolute',
    '& svg': {
      color: '#394ECA !important',
    },
    top: 0,
    right: 0,
    bottom: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  singleValue: (styles) => ({
    ...styles,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px',
    paddingRight: '50px',
    textOverflow: 'none',
    letterSpacing: '0em',
    color: '#3F4049 !important',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 6.03784L7.86396 12.0758L14.2279 6.03784' stroke='%230018A8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")`,
    backgroundRepeat: 'no-repeat',
    '& svg path': {
      color: 'transparent',
    },
    backgroundPosition: 'center center',
  }),
  multiValue: (styles) => ({
    ...styles,
    ...tagStyles,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    paddingLeft: '8px !important',
  }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0em',
    color: '#AFB3CB',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    ...tagStyles,
  }),
};

const redesignSelectStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles, state) => {
    return {
      ...styles,
      background: '#E5E8F8',
      border: `none`,
      borderColor: state.menuIsOpen ? '#0018a8 !important' : '#3f4049',
      transition: '0.25s border-color ease-in-out !important',
      borderRadius: '6px',
      padding: state.hasValue ? '2px 35px 2px 2px' : '2px 52px 2px 2px',
      width: '100%',
      boxShadow: 'unset',
      '&:hover, &:focus': {
        borderColor: '#0018a8',
      },
      '&:active': {
        borderColor: '#3f4049',
      },
      outline: 'none',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '110%',
      color: '#3f4049',
      letterSpacing: '0em',
      minHeight: '56px',
      textTransform: 'lowercase',
      cursor: 'pointer',
    };
  },
  option: (provided) => {
    return {
      ...provided,
      zIndex: 10000000000000000,
      color: '#21253E',
      background: '#fff',
      height: 40,
      fontSize: '14px',
      lineHeight: '15px',
      padding: '13px 16px',

      '&:hover': {
        background: '#F5F7FE',
        borderRadius: '8px',
      },

      cursor: 'pointer',
    };
  },
  menuList: (styles) => ({
    ...styles,
    background: '#fff',
    borderRadius: 8,
    zIndex: 9999,
    maxHeight: '160px',
    filter: 'drop-shadow(0px -4px 15px rgba(57, 78, 202, 0.1))',

    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      background: '#e5e8f8',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#394eca',
      height: '48px !important',
      borderRadius: '22px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#394eca',
      height: '48px !important',
      borderRadius: '22px',
    },
  }),
  input: (styles, state) => ({
    ...styles,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0em',
    color: '#3F4049 !important',
    position: state.hasValue || state.selectProps.value ? 'relative' : 'absolute',
    top: state.hasValue || state.selectProps.value ? 'auto' : '20px',
    transition: 'top 0.1s, font-size 0.1s',
  }),
  menu: (styles) => ({
    ...styles,
    background: '#EFEFEF',
    borderRadius: 8,
    zIndex: 9999,
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    padding: `0 4px`,
    alignItems: 'flex-start',
    display: `flex`,
    height: state.hasValue || state.selectProps.valueContainer ? 'auto' : '50px',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    position: 'absolute',
    '& svg': {
      color: '#394ECA !important',
    },
    top: 0,
    right: 0,
    bottom: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  singleValue: (styles) => ({
    ...styles,
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '140%',
    paddingRight: '50px',
    textOverflow: 'none',
    letterSpacing: '0em',
    color: '#3F4049 !important',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    backgroundRepeat: 'no-repeat',
    '& svg path': {
      fill: 'transparent',
    },
    backgroundPosition: 'center center',
  }),
  multiValue: (styles) => ({
    ...styles,
    ...tagStyles,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#394ECA',
    margin: '2px 4px 2px',
    height: 24,
    borderRadius: 6,
    padding: 0,
    width: 'fit-content',
  }),
  placeholder: (styles, state) => ({
    ...styles,
    fontFamily: 'Roboto',
    fontSize: state.hasValue || state.selectProps.inputValue ? 14 : 16,
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '0em',
    color: '#747889',
    width: '250px',
    position: state.hasValue || state.selectProps.value ? 'relative' : 'absolute',
    top: state.hasValue || state.selectProps.value ? '25%' : '0',
    transition: 'top 0.1s, font-size 0.1s',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    ...tagStyles,
    background: '#394ECA',
    height: 24,
    padding: 0,
    margin: 0,
  }),
  MultiValueRemove: (styles) => ({
    ...styles,
    '& svg': {
      width: 16,
      height: 16,
    },
  }),
};

const redesignSelectSingleStyles = {
  container: (provided) => ({
    ...provided,
    border: '0',
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    background: '#E5E8F8',
    borderRadius: '8px',
    boxShadow: 'none',
    border: '0',
    height: '56px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: 'visible',
    top: state.hasValue || state.selectProps.inputValue ? 10 : 'auto',
    padding: '0 4px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '140%',
    color: state.isDisabled ? '#394ECA' : '#21253E',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    color: state.menuIsOpen ? '#394ECA !important' : '#747889',
    top: state.hasValue || state.selectProps.inputValue ? -20 : 'auto',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: state.hasValue || state.selectProps.inputValue ? 14 : 16,
    width: '100%',
  }),
  menu: (provided) => ({
    ...provided,
    position: 'relative',
    filter: 'drop-shadow(0px -4px 15px rgba(57, 78, 202, 0.1))',
    borderRadius: '8px',
    border: '0',
    boxShadow: 'none',
    maxHeight: '160px',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '160px',
    padding: '0',

    '::-webkit-scrollbar': {
      width: '6px',
      height: '0',
    },
    '::-webkit-scrollbar-track': {
      background: '#E5E8F8',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#394ECA',
      borderRadius: 22,
      height: 48,
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#394ECA',
      borderRadius: 22,
      height: 48,
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    position: 'absolute',
    '& svg': {
      color: '#394ECA !important',
    },
    top: 0,
    right: 0,
    bottom: 0,
  }),
  option: (provided) => ({
    ...provided,
    padding: '12px 8px',
    minHeight: '40px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '140%',
    color: '#21253E',
    background: '#fff',
    borderRadius: '8px',

    '&:hover': {
      background: '#F5F7FE',
      borderRadius: '8px',
    },
  }),
};

const redesignSelectMultiStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles, state) => {
    return {
      ...styles,
      background: '#E5E8F8',
      border: `none`,
      borderColor: state.menuIsOpen ? '#0018a8 !important' : '#3f4049',
      transition: '0.25s border-color ease-in-out !important',
      borderRadius: '6px',
      padding: '2px 52px 2px 2px',
      width: '100%',
      boxShadow: 'unset',
      '&:hover, &:focus': {
        borderColor: '#0018a8',
      },
      '&:active': {
        borderColor: '#3f4049',
      },
      outline: 'none',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '110%',
      color: '#3f4049',
      letterSpacing: '0em',
      height: state.isMulti ? 'auto' : '56px',
      textTransform: 'lowercase',
      cursor: 'pointer',
      minHeight: '56px',
    };
  },
  option: (provided) => {
    return {
      ...provided,
      zIndex: 10000000000000000,
      color: '#21253E',
      background: '#fff',
      height: 40,
      fontSize: '14px',
      lineHeight: '15px',
      padding: '13px 16px',

      '&:hover': {
        background: '#F5F7FE',
        borderRadius: '8px',
      },

      cursor: 'pointer',
    };
  },
  menuList: (styles) => ({
    ...styles,
    background: '#fff',
    borderRadius: 8,
    zIndex: 9999,
    maxHeight: '160px',
    filter: 'drop-shadow(0px -4px 15px rgba(57, 78, 202, 0.1))',

    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      background: '#e5e8f8',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#394eca',
      height: '48px !important',
      borderRadius: '22px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#394eca',
      height: '48px !important',
      borderRadius: '22px',
    },
  }),
  input: (styles) => ({
    ...styles,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0em',
    color: '#3F4049 !important',
  }),
  menu: (styles) => ({
    ...styles,
    background: '#EFEFEF',
    borderRadius: 8,
    zIndex: 9999,
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    padding: `0 5px`,
    display: !state.hasValue && state.selectProps.inputValue === '' ? 'grid' : 'flex',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    position: 'absolute',
    '& svg': {
      color: '#394ECA !important',
    },
    top: 0,
    right: 0,
    bottom: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  singleValue: (styles) => ({
    ...styles,
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '140%',
    paddingRight: '50px',
    textOverflow: 'none',
    letterSpacing: '0em',
    color: '#3F4049 !important',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    backgroundRepeat: 'no-repeat',
    '& svg path': {
      fill: 'transparent',
    },
    backgroundPosition: 'center center',
  }),
  multiValue: (styles) => ({
    ...styles,
    ...tagStyles,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#394ECA',
    gap: '6px',
    height: 24,
    width: 'fit-content',
    borderRadius: 6,
    padding: 0,
  }),
  placeholder: (styles, state) => ({
    ...styles,
    fontFamily: 'Roboto',
    fontSize: state.hasValue || state.selectProps.inputValue ? 14 : 16,
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '0em',
    color: '#747889',
    width: '100%',
    margin: '0',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    ...tagStyles,
    background: '#394ECA',
    height: 24,
    padding: 0,
    margin: 0,
  }),
  MultiValueRemove: (styles) => ({
    ...styles,
    '& svg': {
      width: '16px !important',
      height: '16px !important',
    },
  }),
};

export {
  selectStyles,
  redesignSelectStyles,
  redesignSelectSingleStyles,
  redesignSelectMultiStyles,
};
