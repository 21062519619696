import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Social } from '@ui/Social/Social';
import { signInWithYandex } from '@store/auth';

const YandexAuthButton = ({ onDone, isLogin, isRedesign }) => {
  const dispatch = useDispatch();
  const [childWindow, setChildWindow] = useState(null);

  const buttonClicked = () => {
    const url =
      `https://oauth.yandex.ru/authorize` +
      `?response_type=token` +
      `&client_id=${process.env.REACT_APP_YANDEX_APP_ID}`;
    setChildWindow(
      window.open(
        url,
        '_blank',
        'location=yes,height=570,width=520,scrollbars=yes,status=yes,top=200,left=720',
      ),
    );
  };

  const messageCallback = useCallback((ev) => {
    const url = ev.data;
    if (typeof url !== 'string' || !childWindow || !url.startsWith('https://')) return;
    childWindow.close();
    dispatch(signInWithYandex({ url: ev.data, isLogin }));
    setChildWindow(null);
    onDone();
  });

  useEffect(() => {
    window.addEventListener('message', messageCallback, false);
    return () => {
      window.removeEventListener('message', messageCallback);
    };
  }, [messageCallback]);

  return (
    <div>
      <Social name='yandex' onClick={buttonClicked} isRedesign={isRedesign} />
    </div>
  );
};

export default YandexAuthButton;
