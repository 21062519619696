import { Social } from '@ui/Social/Social';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { signInWithMailru } from 'store/auth';

const MailruAuthButton = ({ onDone, isLogin, isRedesign }) => {
  const dispatch = useDispatch();
  const [childWindow, setChildWindow] = useState(null);

  const buttonClicked = () => {
    const url =
      `https://oauth.mail.ru/login` +
      `?client_id=${process.env.REACT_APP_MAILRU_APP_ID}` +
      `&response_type=code` +
      `&scope=userinfo` +
      `&redirect_uri=${
        process.env.REACT_APP_MAILRU_REDIRECT_URL ||
        'https://leaderport.ru/api/auth/mailru/redirect'
      }&scope` +
      `&state=urn:ietf:wg:oauth:2.0:oob:auto`;
    setChildWindow(
      window.open(
        url,
        '_blank',
        'location=yes,height=570,width=520,scrollbars=yes,status=yes,top=200,left=720',
      ),
    );
  };

  const messageCallback = useCallback((ev) => {
    if (typeof ev.data !== 'string' || !childWindow) return;
    childWindow.close();
    dispatch(signInWithMailru({ url: ev.data, isLogin }));
    setChildWindow(null);
    onDone();
  });

  useEffect(() => {
    window.addEventListener('message', messageCallback, false);
    return () => {
      window.removeEventListener('message', messageCallback);
    };
  }, [messageCallback]);

  return <Social name='mailru' onClick={buttonClicked} isRedesign={isRedesign} />;
};

export default MailruAuthButton;
