import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { fetchUser } from 'store/auth';
import AppRoutes from './AppRoutes';
import GlobalLoader from '@common/GlobalLoader';
import { fetchProfile, loadJobFields, loadSkills } from 'store/profile';
import { FinishImpersonateButton } from 'components/main/auth/FinishImpersonateButton/FinishImpersonateButton';

import styles from './App.module.scss';

window.cookies = Cookies;

function App() {
  const dispatch = useDispatch();
  const isGlobalLoading = useSelector((state) => state.common.isGlobalLoading);
  const user = useSelector((state) => state.auth.user);
  const isImpersonate = useSelector((state) => state.auth.isImpersonate);
  const isLanding = window.location.pathname === '/';

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(loadJobFields());
    dispatch(loadSkills());
    dispatch(fetchProfile());
  }, []);

  return (
    <div className={styles.app + `${isLanding ? ' ' + styles.app_landing : ''}`} id='app-root'>
      <AppRoutes />
      {isGlobalLoading && <GlobalLoader />}
      {user && isImpersonate && <FinishImpersonateButton />}
    </div>
  );
}

export default App;
