import { useMediaQuery } from '@mui/material';

import { Icon } from 'components/core-ui/Icon/Icon';
import { Modal } from 'components/core-ui/Modal/Modal';

import styles from './BusinessInfoModal.module.scss';

export const BusinessInfoModal = ({ closeModal }) => {
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width: 600px)');

  if (isMobile) {
    return (
      <Modal
        onClose={closeModal}
        contentClassName={styles.modalContent}
        closeIconClassName={styles.modalClose}
        isRedesign
      >
        <div>
          <div className={styles.businessModal_header}>
            <span>
              Укажите ваше резидентство <br /> в сообществах
            </span>
          </div>

          <div className={styles.businessModal_body}>
            Эта информация будет видна после проверки модератора в вашем профиле
          </div>
        </div>
      </Modal>
    );
  }

  if (isTablet) {
    return (
      <Modal
        onClose={closeModal}
        contentClassName={styles.modalContent}
        closeIconClassName={styles.modalClose}
        isRedesign
      >
        <div>
          <div className={styles.businessModal_header}>
            <span>Укажите ваше резидентство в сообществах</span>
          </div>

          <div className={styles.businessModal_body}>
            После проверки данных модератором, <br /> эта информация будет видна другим
            пользователям <br /> в вашем профиле
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className={styles.businessModal}>
      <div className={styles.businessModal_header}>
        <span>Укажите ваше резидентство в сообществах</span>

        <div className={styles.businessModal_close} onClick={closeModal}>
          <Icon name='close' size='mobile-default' />
        </div>
      </div>
      <div className={styles.businessModal_body}>
        После проверки данных модератором, эта информация будет видна другим пользователям в вашем
        профиле
      </div>
    </div>
  );
};
