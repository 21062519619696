import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Icon } from '../Icon/Icon';

import styles from './Social.module.scss';

const Social = ({ name, size, alwaysActive, onClick, isRedesign }) => {
  const iconClicked = (ev) => {
    onClick(ev);
  };

  return (
    <div
      className={clsx(styles.social, { [styles.social_redesign]: isRedesign })}
      data-size={size}
      onClick={iconClicked}
      data-always-active={alwaysActive ? 'active' : ''}
    >
      <Icon name={name} />
    </div>
  );
};

Social.propTypes = {
  name: PropTypes.oneOf(['google', 'vk', 'yandex', 'mailru', 'gu', 'leader']).isRequired,
  size: PropTypes.oneOf(['small', 'normal', 'big']),
  alwaysActive: PropTypes.bool,
  onClick: PropTypes.func,
};

Social.defaultProps = {
  size: 'normal',
  alwaysActive: true,
  onClick: () => {},
};

export { Social };
