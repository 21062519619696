export const formatEventDate = (start, finish) => {
  if (start.year() !== finish.year()) {
    return `${start.format('DD.MM.YYYY')} - ${finish.format('DD.MM.YYYY')}`;
  }
  if (start.month() !== finish.month()) {
    return `${start.format('D MMMM')} - ${finish.format('D MMMM')} ${start.format('YYYY')}`;
  }
  if (start.format('D') === finish.format('D'))
    return `${start.format('D')} ${start.format('DD MMMM YYYY').slice(3)}`;
  return `${start.format('D')} - ${finish.format('D')} ${start.format('DD MMMM YYYY').slice(3)}`;
};
