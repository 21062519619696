import { createSlice } from '@reduxjs/toolkit';

import { stopLoading } from './common';

import axios from 'util/axios';
import profileSlice from './profile';
import { fetchUser } from './auth';

export const completionSlice = createSlice({
  name: 'completion',
  initialState: {
    initialData: null,
    isSubmitting: false,
    redesignStep: 1,
    step: 1,
    isServices: false,
  },
  reducers: {
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    changeRedesignStep: (state, action) => {
      state.redesignStep = action.payload;
    },
    nextStep: (state) => {
      let newStep = state.step + 1;
      if (newStep > 5) newStep = 5;
      state.step = newStep;
    },
    prevStep: (state) => {
      let newStep = state.step - 1;
      if (newStep < 1) newStep = 1;
      state.step = newStep;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    reset: (state) => {
      state.initialData = null;
      state.isSubmitting = false;
      state.step = 1;
    },
    setIsServices: (state, action) => {
      state.isServices = action.payload;
    },
  },
});

export const completePersonal = (data) => async (dispatch) => {
  const response = await axios.put('/profile/completion/personal/', {
    info: data,
  });
  dispatch(profileSlice.actions.setProfile(response.data.profile));
  dispatch(completionSlice.actions.nextStep());
  dispatch(stopLoading());
};

export const completeJobAndEducation = (data) => async (dispatch) => {
  const response = await axios.post('/profile/completion/job-and-education/', data);
  dispatch(profileSlice.actions.setProfile(response.data.profile));
  dispatch(completionSlice.actions.nextStep());
  dispatch(stopLoading());
};

export const completeSkills = (data) => async (dispatch) => {
  const response = await axios.put('/profile/completion/skills/', {
    skills: data,
  });
  dispatch(profileSlice.actions.setProfile(response.data.profile));
  dispatch(fetchUser());
  dispatch(completionSlice.actions.nextStep());
  dispatch(stopLoading());
};

export const setServices = (data) => (dispatch) => {
  dispatch(completionSlice.actions.setIsServices(data));
};

export default completionSlice;
