import { clsx } from 'clsx';

import styles from './Logo.module.scss';

const Logo = ({ isRedesign, isMenu, src = '/images/logo/logo.svg' }) => {
  const isPWA = window.matchMedia('(display-mode: standalone)').matches;

  return (
    <div className={clsx(styles.logo, { [styles.redesign]: isRedesign, [styles.menu]: isMenu })}>
      <a href={isPWA ? '/login' : '/'}>
        <img src={src} alt='Leaderport' />
      </a>
    </div>
  );
};

export default Logo;
