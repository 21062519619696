import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchBusinessCommunities } from 'store/businessCommunity';

export const useBusinessCommunities = () => {
  const dispatch = useDispatch();
  const { businessCommunities } = useSelector((state) => state.businessCommunity);

  useEffect(() => {
    if (!businessCommunities) dispatch(fetchBusinessCommunities());
  }, [dispatch, businessCommunities]);

  return businessCommunities;
};
