import PropTypes from 'prop-types';

import styles from './Invalid.module.scss';

const Invalid = ({ children }) => {
  return <div className={styles.invalid}>{children}</div>;
};

Invalid.propTypes = {
  children: PropTypes.string.isRequired,
};

export { Invalid };
