import { Button } from 'components/core-ui/Button/Button';
import { Icon } from 'components/core-ui/Icon/Icon';
import styles from './FinishImpersonateButton.module.scss';

export const FinishImpersonateButton = () => {
  const finishImpersonate = () => {
    window.open('/api/auth/finish-impersonate/', '_top');
  };

  return (
    <div className={styles.button}>
      <Button outline={true} size='small' icon='logout' onClick={finishImpersonate}>
        Вернуться в панель администратора
      </Button>
    </div>
  );
};
