import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isGlobalLoading: true,
    loading: false,
    showInvitation: false,
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setGlobalLoading(state, action) {
      state.isGlobalLoading = action.payload;
    },
    showInvitation(state) {
      state.showInvitation = true;
    },
    hideInvitation(state) {
      state.showInvitation = false;
    },
  },
});

export const stopLoading = () => async (dispatch) => {
  dispatch(commonSlice.actions.setLoading(false));
};

export const updateGlobalLoading = (value) => async (dispatch) => {
  await dispatch(commonSlice.actions.setGlobalLoading(value));
};

export default commonSlice;
