import { createSlice } from '@reduxjs/toolkit';
import axios from '@util/axios';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    initDone: false,
    isImpersonate: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.initDone = true;
    },
    setPicture: (state, action) => {
      state.user.avatar = action.payload;
    },
    setImpersonate: (state, action) => {
      state.isImpersonate = action.payload;
    },
  },
});

export const fetchUser = () => async (dispatch) => {
  const response = await axios.get('/auth/user/');
  dispatch(authSlice.actions.setImpersonate(response.data.isImpersonate));
  dispatch(authSlice.actions.setUser(response.data.user));
};

export const logout = () => {
  return async () => {
    await axios.get('/auth/logout');
    window.open(location.origin, '_top');
  };
};

window.logout = logout();

export const logoutRedesign = () => async () => {
  await axios.get('/auth/logout');
};

export const removeUser = (data) => async (dispatch) => {
  const response = await axios.post('/auth/remove/', data);
  dispatch(authSlice.actions.setUser(response.data.user));
};

export const restoreUser = () => async (dispatch) => {
  const response = await axios.post('/auth/restore/');
  dispatch(authSlice.actions.setUser(response.data.user));
};

// Signing in ====================================================================

export const signInWithGoogle = ({ response, isLogin }) => {
  return async () => {
    if (!('accessToken' in response)) return;
    await axios.get(`/auth/google-auth?token=${response.accessToken}`);
    isLogin && window.open('/completion/', '_top');
  };
};

export const signInWithFacebook = ({ response, isLogin }) => {
  return async () => {
    if (!('accessToken' in response)) return;
    await axios.post(`/auth/facebook`, {
      token: response.accessToken,
    });
    isLogin && window.open('/completion/', '_top');
  };
};

export const signInWithVk = ({ url, isLogin }) => {
  return async () => {
    await axios.post(`/auth/vk`, { url });
    isLogin && window.open('/completion/', '_top');
  };
};

export const signInWithYandex = ({ url, isLogin }) => {
  return async () => {
    await axios.post(`/auth/yandex`, { url });
    isLogin && window.open('/completion/', '_top');
  };
};

export const signInWithMailru = ({ url, isLogin }) => {
  return async () => {
    await axios.post(`/auth/mailru`, { url });
    isLogin && window.open('/completion/', '_top');
  };
};

export default authSlice;
