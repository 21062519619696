import { createSlice } from '@reduxjs/toolkit';
import axios from 'util/axios';

export const businessCommunitySlice = createSlice({
  name: 'businessCommunity',
  initialState: {
    businessCommunities: null,
  },
  reducers: {
    setBusinessCommunities: (state, { payload }) => {
      state.businessCommunities = payload;
    },
  },
});

export const fetchBusinessCommunities = () => async (dispatch) => {
  const response = await axios.get('/profile/communities/filter/?limit=20&text=');
  dispatch(businessCommunitySlice.actions.setBusinessCommunities(response.data));
};

export default businessCommunitySlice;
