import { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import axios from 'util/axios';
import { useMediaQuery } from '@mui/material';
import { CSSTransition } from 'react-transition-group';

import { stringToOption, optionToString } from 'util/transform';

import { Button } from 'components/core-ui/Button/Button';
import { DrawerBlock } from 'components/core-ui/DrawerBlock/DrawerBlock';
import { Icon } from 'components/core-ui/Icon/Icon';
import { TextInput } from 'components/core-ui/TextInput/TextInput';
import { CustomDropdownIndication } from 'components/core-ui/Select/components/Components';

import { selectStyles, redesignSelectMultiStyles } from 'components/core-ui/select-styles';
import {
  redesignSelectTabletStyles,
  redesignSelectMobileStyles,
} from 'components/core-ui/select-styles-mobile';
import styles from './CityInput.module.scss';

const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <div className={styles.search}>
      <Icon name='search'></Icon>
    </div>
    {children}
  </components.Control>
);

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {children.map((child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );
};

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete={'nope'} />;

const CityInput = ({
  value,
  onChange,
  country,
  multiple,
  placeholder,
  name,
  onBlur,
  placeholderDrawer,
  isRegister,
  selectedCity = '',
}) => {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();

  const defaultValue = multiple ? value.map(stringToOption) : stringToOption(value);
  const selectedValue = multiple ? value.map(stringToOption) : stringToOption(value);

  const [, setInitDone] = useState(false);
  const [isDrawerBlock, setIsDrawerBlock] = useState(false);

  const isTablet = useMediaQuery('(max-width: 1024px)');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const drawerBlockRef = useRef(null);

  const countryId = stringToOption(country).value;

  const fetchCities = async (text) => {
    const response = await axios.get(`/profile/places/cities?q=${text}&countryId=${countryId}`);
    return response.data.cities;
  };

  const fetchDefaultOptions = async () => {
    setIsLoading(true);
    let options = (await fetchCities('')).map(stringToOption);

    if (selectedCity) {
      options = options.filter((item) => item.label !== selectedCity.split('|')[0]);
    }

    setDefaultOptions(options);
    setIsLoading(false);
  };

  const fetchOptions = async (text) => {
    setIsLoading(true);
    let options = (await fetchCities(text)).map(stringToOption);

    if (selectedCity) {
      options = options.filter((item) => item.label !== selectedCity.split('|')[0]);
    }

    setIsLoading(false);
    return options;
  };

  const citiesChanged = (options) => {
    if (multiple) {
      return onChange(options.map(optionToString));
    } else if (options) {
      const closeDrawerBlock = new CustomEvent('closeDrawerBlock');
      document.dispatchEvent(closeDrawerBlock);
      setIsDrawerBlock(false);
    }

    onChange(optionToString(options));
  };

  useEffect(() => {
    fetchDefaultOptions();
    setInitDone(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, selectedCity]);

  const handleRemoveValue = (e) => {
    if (!citiesChanged) return;

    const buttonName = e.currentTarget.getAttribute('data-name');
    const removedValue = selectedValue.find((val) => val.label === buttonName);

    if (!removedValue) return;

    citiesChanged(
      selectedValue.filter((val) => val.label !== buttonName),
      { name, action: 'remove-value', removedValue },
    );
  };

  const selectProps = {
    placeholder: placeholder,
    isClearable: true,
    isSearchable: true,
    styles: selectStyles,
    backspaceRemovesValue: true,
    cacheOptions: true,
    defaultOptions: defaultOptions,
    loadOptions: fetchOptions,
    isLoading: isLoading,
    onChange: citiesChanged,
    name,
    onBlur,
    value: selectedValue,
    isMulti: !!multiple,

    className: 'react-select react-select--capital',
    classNamePrefix: 'react-select react-select--capital',
  };

  const valueToString = (arr) => {
    return arr
      .map((val) => {
        return val.split('|')[0];
      })
      .join(', ');
  };

  const setMobileOrTabletStyles = () => {
    if (isMobile) return redesignSelectMobileStyles;
    if (isTablet) return redesignSelectTabletStyles;
  };

  const selectPropsMobile = {
    placeholder: 'поиск',
    isClearable: true,
    isSearchable: true,
    styles: setMobileOrTabletStyles(),
    cacheOptions: true,
    defaultOptions: defaultOptions,
    loadOptions: fetchOptions,
    isLoading: isLoading,
    onChange: citiesChanged,
    name,
    onBlur,
    controlShouldRenderValue: !multiple,
    value: selectedValue,
    isMulti: multiple,
    className: `${styles.react_select} react-select react-select--capital`,
    classNamePrefix: 'react-select react-select--capital',
    defaultMenuIsOpen: multiple && !value.length,
  };

  if (!multiple) {
    selectPropsMobile.menuIsOpen = true;
    selectPropsMobile.closeMenuOnSelect = false;
  }

  const closeClicked = () => {
    if (multiple) {
      citiesChanged(defaultValue);
    }
    setIsDrawerBlock(false);
  };

  const buttonClicked = () => {
    const closeDrawerBlock = new CustomEvent('closeDrawerBlock');
    document.dispatchEvent(closeDrawerBlock);
    setIsDrawerBlock(false);
  };

  return (
    <>
      {isTablet ? (
        <div>
          <div
            onClick={() => {
              setIsDrawerBlock(true);
            }}
          >
            <TextInput
              placeholderMobile={placeholder}
              disabled
              type='text'
              value={
                // eslint-disable-next-line no-nested-ternary
                selectedValue
                  ? Array.isArray(value)
                    ? valueToString(value)
                    : value.split('|')[0]
                  : ''
              }
              autoComplete='off'
              classN
            />
          </div>

          <CSSTransition in={isDrawerBlock} nodeRef={drawerBlockRef} timeout={250} unmountOnExit>
            <div ref={drawerBlockRef}>
              <DrawerBlock
                title={placeholderDrawer ? placeholderDrawer : placeholder}
                closeButton
                onClose={closeClicked}
                isRedesign
              >
                <div className={styles.select}>
                  <div className={styles.input_mobile}>
                    <AsyncSelect
                      components={{ Control }}
                      {...selectPropsMobile}
                      className={styles.react_select}
                      ref={inputRef}
                    />
                    <div className={styles.values}>
                      {multiple
                        ? value.map((val) => {
                            return (
                              <div className={styles.value} key={val.split('|')[0]}>
                                <div
                                  data-name={val.split('|')[0]}
                                  onClick={handleRemoveValue}
                                  className={styles.closeButton}
                                >
                                  <Icon size='small' name='close'></Icon>
                                </div>
                                {val.split('|')[0]}
                              </div>
                            );
                          })
                        : null}
                      {multiple && (
                        <div>
                          <Button
                            className={styles.button}
                            block
                            height={'big'}
                            onClick={buttonClicked}
                          >
                            Готово
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </DrawerBlock>
            </div>
          </CSSTransition>
        </div>
      ) : (
        <div className={styles.input}>
          {!isRegister ? (
            <AsyncSelect menuPlacement='auto' {...selectProps} components={{ Input }} />
          ) : (
            <AsyncSelect
              menuPlacement='auto'
              {...selectProps}
              components={{
                ValueContainer: CustomValueContainer,
                IndicatorsContainer: CustomDropdownIndication,
                DropdownIndicator: null,
                LoadingIndicator: null,
                ClearIndicator: null,
              }}
              isOptionDisabled={() => {
                if (selectedValue && multiple) return selectedValue.length >= 3;
              }}
              styles={redesignSelectMultiStyles}
              hideSelectedOptions
            />
          )}
        </div>
      )}
    </>
  );
};

CityInput.propTypes = {
  value: propTypes.any,
  onChange: propTypes.func.isRequired,
  country: propTypes.string.isRequired,
  multiple: propTypes.bool.isRequired,
  placeholder: propTypes.string.isRequired,
  name: propTypes.string,
  onBlur: propTypes.func,
  capital: propTypes.bool.isRequired,
};

CityInput.defaultProps = {
  country: 'Россия|1',
  multiple: false,
  placeholder: 'Выберите город',
  capital: false,
};

export { CityInput };
