import PropTypes from 'prop-types';

import svgIcons from 'svg/icons';

import styles from '../Icon/Icon.module.scss';

const Icon = ({ name, size }) => {
  const svg = svgIcons[name];
  return (
    <div className={styles.icon} data-size={size} dangerouslySetInnerHTML={{ __html: svg }}></div>
  );
};

Icon.propTypes = {
  size: PropTypes.oneOf([
    'giant',
    'very-big',
    'medium-big',
    'big',
    'mobile-default',
    'medium',
    'twenty',
    '16',
    'small',
    'normal',
    'check',
  ]),
  name: PropTypes.oneOf(Object.keys(svgIcons)),
};

export { Icon };
