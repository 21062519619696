import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import styles from './DrawerBlockHeader.module.scss';

const DrawerBlockHeader = ({
  title,
  subtitle,
  isRedesign = false,
  isProfileVerification = false,
}) => {
  return (
    <header
      data-border={subtitle ? 'border' : ''}
      className={clsx(styles.drawer_block_header, {
        [styles.drawer_block_header_redesign]: isRedesign,
        [styles.drawer_block_header_redesign_hasSubtitle]: isRedesign && subtitle,
        [styles.drawer_block_header_verification]: isProfileVerification,
      })}
    >
      <h2 className={styles.title}>{title}</h2>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    </header>
  );
};

DrawerBlockHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export { DrawerBlockHeader };
