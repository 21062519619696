import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { clsx } from 'clsx';

import { isPwa } from 'util/media';

import completionSlice from 'store/completion';
import { logoutRedesign } from 'store/auth';

import { Button } from 'components/core-ui/Button/Button';
import Logo from 'components/common/Logo';

import { RegisterSwitcher } from 'components/landing-page/components/Switcher/Switcher';
import { RegisterList } from 'components/landing-page/components/RegisterList/RegisterList';
import { RegisterStep } from 'components/landing/pages/RegisterPage/RegisterStep/RegisterStep';
import { ContactsStep } from 'components/landing/pages/RegisterPage/ContactsStep/ContactsStep';
import { BusinessStep } from 'components/landing/pages/RegisterPage/BusinessStep/BusinessStep';
import { SMSStep } from 'components/landing/pages/RegisterPage/SMSStep/SMSStep';
import { AddTabModal } from 'components/main/pages/account/addTabModal/AddTabModal';

import styles from '../RegisterPage.module.scss';

export const RegisterPageMobile = ({ redesignStep, registerRightClasses, onAuthDone }) => {
  const isTablet = useMediaQuery('(max-width: 1024px)');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const dispatch = useDispatch();
  const isPWA = isPwa();
  const [showModal, setShowModal] = useState(true);
  const [title, setTitle] = useState('');
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (isMobile) setStep(1);
    else setStep(2);
  }, [isMobile]);

  useEffect(() => {
    if (redesignStep === 2) setTitle('Оставьте контактные данные');
    if (redesignStep === 3) setTitle('Укажите информацию о вашей деятельности');
    if (redesignStep === 4) setTitle('Верифицируйте ваш аккаунт');
  }, [redesignStep]);

  return (
    <div
      className={clsx(styles.registerPage, {
        [styles.registerPage_infoSteps]: redesignStep === 1,
      })}
    >
      {isTablet && showModal && redesignStep === 1 && !isPWA && (
        <AddTabModal onChange={setShowModal} />
      )}

      <div
        className={clsx(styles.header, {
          [styles.header_medium]: redesignStep === 2 || redesignStep === 4,
          [styles.header_large]: redesignStep === 3,
        })}
      >
        <div className={styles.logoAndExit}>
          <Logo isRedesign />

          {redesignStep !== 1 && (
            <div
              className={styles.exit}
              onClick={() => {
                dispatch(logoutRedesign());
                dispatch(completionSlice.actions.changeRedesignStep(1));
              }}
            >
              Выход
            </div>
          )}
        </div>

        {(redesignStep === 2 || redesignStep === 3 || redesignStep === 4) && (
          <div
            className={clsx(styles.titleAndSteps, {
              [styles.titleAndSteps_margin]: redesignStep === 4,
            })}
          >
            <div
              className={clsx(styles.title, {
                [styles.title_large]: redesignStep === 3,
              })}
            >
              {title}
            </div>
            <span className={styles.steps}>{`${redesignStep}/4`}</span>
          </div>
        )}
      </div>

      <div className={registerRightClasses}>
        {redesignStep === 1 && <RegisterStep onAuthDone={onAuthDone} />}
        {redesignStep === 2 && <ContactsStep />}
        {redesignStep === 3 && <BusinessStep />}
        {redesignStep === 4 && <SMSStep />}
        {redesignStep === 5 && (
          <div className={styles.left_progress}>
            <h1 className={styles.left_progress_header}>Вот что вам предстоит сделать:</h1>
            <div className={styles.left_progress_body}>
              <RegisterSwitcher step={step} />
              <RegisterList />
            </div>

            <Button
              className={styles.nextStep}
              onClick={() => dispatch(completionSlice.actions.changeRedesignStep(2))}
            >
              Вперед
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
