import { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { clsx } from 'clsx';

import { Icon } from 'components/core-ui/Icon/Icon';
import { DrawerBlock } from 'components/core-ui/DrawerBlock/DrawerBlock';
import { Gallery } from 'components/core-ui/Gallery/Gallery';
import { RedesignImageCropper } from './RedesignImageCropper';
import { ImageSettingModal } from './ImageSettingModal';

import styles from './RedesignImagePicker.module.scss';

export const RedesignImagePicker = ({ onChange, isTablet, onRemove }) => {
  const fileInputRef = useRef();
  const [dataUrl, setDataUrl] = useState('');
  const [error, setError] = useState(null);
  const [isDrawerBlock, setIsDrawerBlock] = useState(false);
  const [isImageSettingModal, setIsImageSettingModal] = useState(false);
  const [image, setImage] = useState(null);

  const closeDrawerBlock = () => {
    let closeEvent = new CustomEvent('closeDrawerBlock');
    document.dispatchEvent(closeEvent);
    setIsDrawerBlock(false);
  };

  const selectFile = (e) => {
    e.preventDefault();
    let files;

    if (e.dataTransfer) {
      // eslint-disable-next-line prefer-destructuring
      files = e.dataTransfer.files;
    } else if (e.target) {
      // eslint-disable-next-line prefer-destructuring
      files = e.target.files;

      if (
        files[0].type !== 'image/png' &&
        files[0].type !== 'image/jpeg' &&
        files[0].type !== 'image/jpg'
      ) {
        isTablet && closeDrawerBlock();
        return setError('Неподдерживаемое расширение файла');
      }
      const { size } = files[0];
      setError(null);
      if (size > 10 * 1024 * 1024) {
        isTablet && closeDrawerBlock();
        return setError('Выбран слишком большой файл');
      }
    }
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        let image = new Image();
        if (typeof this.result !== 'string') {
          isTablet && closeDrawerBlock();
          setError('Ошибка чтения файла');
        } else {
          image.src = this.result;
          image.addEventListener('load', function () {
            if (this.width < 400 && this.height < 400) {
              isTablet && closeDrawerBlock();
              setError('Фотография должна быть не менее 400 пикселей в высоту и ширину');
            } else {
              isTablet && closeDrawerBlock();
            }
          });
        }
      },
      false,
    );

    reader.onload = () => {
      setImage(reader.result);
    };

    reader.readAsDataURL(files[0]);
  };

  const dropClicked = () => {
    setIsImageSettingModal(true);
  };

  const cropperDone = (blob) => {
    setDataUrl(URL.createObjectURL(blob));
    onChange(blob);
    setIsImageSettingModal(false);
    setImage(null);
  };

  const closeCropper = () => {
    setDataUrl(null);
    setError(null);
    fileInputRef.current.value = null;
  };

  const galleryClicked = () => {
    fileInputRef.current.click();
  };

  const drawerBlockRef = useRef(null);

  const onCancelCropper = () => {
    setImage(null);
    fileInputRef.current.value = null;
    setIsImageSettingModal(false);
  };

  const removePicture = () => {
    let closeEvent = new CustomEvent('closeDrawerBlock');

    document.dispatchEvent(closeEvent);
    fileInputRef.current.value = null;
    setImage(null);
    setIsDrawerBlock(false);
    setDataUrl(null);
    onRemove();
  };

  return (
    <>
      <div className={clsx(styles.imagePicker, { [styles.onImageError]: error && isTablet })}>
        <div className={styles.drop}>
          <div className={styles.area} onClick={dropClicked}>
            {dataUrl ? (
              <img src={dataUrl} alt='' className={styles.areaImage} />
            ) : (
              <div className={styles.plusIcon}>
                <Icon name='avatar' size='giant' />
              </div>
            )}
          </div>
        </div>

        <div className={styles.description}>
          <div className={styles.uploadButton}>
            <section>
              {!dataUrl ? (
                <div className={styles.changePhoto} onClick={dropClicked}>
                  <span>Загрузить фото</span>
                </div>
              ) : (
                <div
                  className={clsx(styles.changePhoto, { [styles.changePhoto_remove]: dataUrl })}
                  onClick={removePicture}
                >
                  <Icon name='remove' size='medium' />
                  <span>Удалить фото</span>
                </div>
              )}
            </section>
          </div>
        </div>

        <div className={styles.input}>
          <input type='file' onChange={selectFile} ref={fileInputRef} accept='.jpg, .jpeg, .png' />
        </div>
        {isImageSettingModal &&
          createPortal(
            <ImageSettingModal
              error={error}
              onClose={() => {
                setIsImageSettingModal(false);
                setError(null);
                setImage(null);
              }}
              openImageCropper={() => fileInputRef.current.click()}
            />,
            document.body,
          )}

        {image &&
          !error &&
          createPortal(
            <RedesignImageCropper
              image={image}
              onDone={cropperDone}
              onBack={closeCropper}
              onCancel={onCancelCropper}
              changePhoto={() => fileInputRef.current.click()}
            />,
            document.body,
          )}

        <CSSTransition in={isDrawerBlock} nodeRef={drawerBlockRef} timeout={250} unmountOnExit>
          <div ref={drawerBlockRef}>
            <DrawerBlock close onClose={() => setIsDrawerBlock(false)}>
              <Gallery onClick={galleryClicked}></Gallery>
            </DrawerBlock>
          </div>
        </CSSTransition>
      </div>
    </>
  );
};
