import { createSlice } from '@reduxjs/toolkit';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    // services: [
    //   { name: 'system', notifications: 0, title: 'системные инициативы' },
    //   { name: 'club', notifications: 0, title: 'картирование' },
    //   { name: 'calendar', notifications: 0, title: 'календарь событий' },
    //   { name: 'hrsm', notifications: 0, title: 'маркетплейс кадров' },
    // ],
    isShowNotifications: false,
  },
  reducers: {
    setIsShowNotifications: (state, { payload }) => {
      state.isShowNotifications = payload;
    },
  },
});

export default notificationsSlice;
