import { useRef } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from '@mui/material';

import { Icon } from 'components/core-ui/Icon/Icon';
import GoogleAuthButton from '@auth/buttons/GoogleAuthButton';
import VkAuthButton from '@auth/buttons/VkAuthButton';
import YandexAuthButton from '@auth/buttons/YandexAuthButton';
import GuAuthButton from '@auth/buttons/GuAuthButton';
import LeaderAuthButton from '@auth/buttons/LeaderAuthButton';
import MailruAuthButton from '@main/auth/buttons/MailruAuthButton';

import styles from './SectionSlider.module.scss';

export const SectionSlider = (props) => {
  const { onAuthDone, isLogin } = props;
  const isMobile = useMediaQuery('(max-width: 600px)');

  const swiperRef = useRef(null);

  return (
    <div className={styles.swiperWrap}>
      <button
        className={styles.button}
        aria-label='Предыдущий слайд'
        onClick={() => swiperRef.current.swiper.slidePrev()}
      >
        <Icon name='authSliderLeft' size='big' />
      </button>

      <Swiper
        ref={swiperRef}
        modules={[Navigation]}
        spaceBetween={!isMobile ? 16 : 8}
        slidesPerView={4}
        className={styles.swiper}
        allowTouchMove
        loop
      >
        <SwiperSlide className={styles.swiper_slide}>
          <GoogleAuthButton onDone={onAuthDone} isLogin={isLogin} isRedesign />
        </SwiperSlide>
        <SwiperSlide className={styles.swiper_slide}>
          <MailruAuthButton onDone={onAuthDone} isLogin={isLogin} isRedesign />
        </SwiperSlide>
        <SwiperSlide className={styles.swiper_slide}>
          <VkAuthButton onDone={onAuthDone} isLogin={isLogin} isRedesign />
        </SwiperSlide>
        <SwiperSlide className={styles.swiper_slide}>
          <YandexAuthButton onDone={onAuthDone} isLogin={isLogin} isRedesign />
        </SwiperSlide>
        <SwiperSlide className={styles.swiper_slide}>
          <GuAuthButton onDone={onAuthDone} isRedesign />
        </SwiperSlide>
        <SwiperSlide className={styles.swiper_slide}>
          <LeaderAuthButton onDone={onAuthDone} isRedesign />
        </SwiperSlide>
      </Swiper>

      <button
        className={styles.button}
        aria-label='Следующий слайд'
        onClick={() => swiperRef.current.swiper.slideNext()}
      >
        <Icon name='authSliderRight' size='big' />
      </button>
    </div>
  );
};
