import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import { Tooltip } from '../Tooltip/Tooltip';

const Popover = ({ children, tooltip, isActive, size, align, isAlignTop = false }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const isTablet = useMediaQuery('(max-width:968px)');

  const updatePosition = (ev) => {
    setPosition({
      x: ev.clientX + (isAlignTop ? -200 : 10),
      y: ev.clientY + (isAlignTop ? -25 : 10),
    });
  };

  useEffect(() => {
    document.addEventListener('mousemove', updatePosition);
    return () => {
      document.removeEventListener('mousemove', updatePosition);
    };
  }, []);

  return (
    <div onMouseMove={(ev) => setShowTooltip(true)} onMouseLeave={(ev) => setShowTooltip(false)}>
      {showTooltip && !isTablet && isActive && (
        <Tooltip
          text={tooltip || ''}
          size={size}
          isAlignTop={isAlignTop}
          style={{
            position: 'fixed',
            top: position.y + 'px',
            left: position.x + 'px',
            textTransform: 'none',
            textAlign: align,
          }}
        />
      )}
      {children}
    </div>
  );
};

Popover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  tooltip: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['small', 'big', 'normal', 'large']),
  align: PropTypes.oneOf(['center', 'end', 'start', 'right', 'left']),
};

Popover.defaultProps = {
  isActive: true,
  size: 'normal',
  align: 'left',
};

export { Popover };
