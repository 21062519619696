import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useMediaQuery } from '@mui/material';

import { Icon } from '../Icon/Icon';

import styles from './Modal.module.scss';

const Modal = ({
  onClose,
  children,
  className,
  containerClassName,
  contentClassName,
  closeIconClassName,
  isRedesign,
  variant,
  iconPosition,
  customCloseIcon = false,
}) => {
  const isTablet = useMediaQuery('(max-width: 1024px)');

  const containerClasses = clsx(styles.container, containerClassName);
  const contentClasses = clsx(styles.content, contentClassName, {
    [styles.content_redesign]: isRedesign,
  });
  const closeIconClasses = clsx(styles.close, closeIconClassName, {
    [styles.close_pos]: iconPosition,
  });

  return createPortal(
    <div className={`${styles.modal} ${className}`}>
      <div className={styles.backdrop}></div>
      <div className={containerClasses}>
        <div className={contentClasses} data-variant={variant}>
          {customCloseIcon ? (
            customCloseIcon
          ) : (
            <div className={closeIconClasses} onClick={() => onClose && onClose()}>
              <Icon
                name={isTablet ? 'closeMobile' : 'close'}
                size={isTablet ? 'big' : 'mobile-default'}
              />
            </div>
          )}
          <section>{children}</section>
        </div>
      </div>
    </div>,
    document.getElementById('root-overlay'),
  );
};

Modal.propTypes = {
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['default']),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export { Modal };
