import { createSlice } from '@reduxjs/toolkit';
import axios from 'util/axios';

import { stopLoading } from './common';

import profileSlice from './profile';

export const registrationSlice = createSlice({
  name: 'registration',
  initialState: {
    initialData: null,
    isSubmitting: false,
    redesignStep: 3,
    step: 1,
    isServices: false,
    isEditPhone: false,
    incorrectCode: false,
    isShowIncorrectTooltip: false,
    isManagerCallStep: false,
    isRegistrationSuccess: false,
  },
  reducers: {
    setSubmitting: (state, { payload }) => {
      state.isSubmitting = payload;
    },
    changeRedesignStep: (state, { payload }) => {
      state.redesignStep = payload;
    },
    nextStep: (state) => {
      let newStep = state.step + 1;
      if (newStep > 5) newStep = 5;
      state.step = newStep;
    },
    prevStep: (state) => {
      let newStep = state.step - 1;
      if (newStep < 1) newStep = 1;
      state.step = newStep;
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    reset: (state) => {
      state.initialData = null;
      state.isSubmitting = false;
      state.step = 1;
    },
    setIsServices: (state, { payload }) => {
      state.isServices = payload;
    },
    setIsEditPhone: (state, { payload }) => {
      state.isEditPhone = payload;
    },
    setIsIncorrectCode: (state, { payload }) => {
      state.incorrectCode = payload;
    },
    setIsShowIncorrectTooltip: (state, { payload }) => {
      state.isShowIncorrectTooltip = payload;
    },
    setIsManagerCallStep: (state, { payload }) => {
      state.isManagerCallStep = payload;
    },
    setIsRegistrationSuccess: (state, { payload }) => {
      state.isRegistrationSuccess = payload;
    },
  },
});

export const completeRegistrationPersonal = (data) => async (dispatch) => {
  const response = await axios.put('/profile/registration/personal/', {
    city: data.city,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
  });
  dispatch(profileSlice.actions.setProfile(response.data.profile));
  dispatch(stopLoading());
};

export const completeRegistrationJobAndCommunity = (data) => async (dispatch) => {
  const response = await axios.put('/profile/registration/job-and-communities', data);
  dispatch(profileSlice.actions.setProfile(response.data.profile));
  dispatch(stopLoading());
};

export const completeRegistrationSendCode = () => async (dispatch) => {
  await axios.post('/profile/registration/call-phone-verification');
  dispatch(stopLoading());
};

export const sendSMSRegistrationCode = () => async (dispatch) => {
  await axios.post('/profile/registration/send-phone-verification');
  dispatch(stopLoading());
};

export const completeRegistrationVerifyPhone = (code) => async (dispatch) => {
  const response = await axios.put('/profile/registration/verify-phone', code);

  if (!response.data.success) {
    dispatch(registrationSlice.actions.setIsIncorrectCode(true));
    dispatch(registrationSlice.actions.setIsShowIncorrectTooltip(true));
  } else {
    dispatch(registrationSlice.actions.setIsManagerCallStep(true));
  }
};

export const completeDateTimeManagerCall = (dateTimeAndMethod, isProfile) => async (dispatch) => {
  const response = await axios.post('/profile/registration/verify-date-time', dateTimeAndMethod);
  if (response.data.success) {
    dispatch(profileSlice.actions.setProfile(response.data.profile));
    if (!isProfile) dispatch(registrationSlice.actions.setIsRegistrationSuccess(true));
  }
};

export default registrationSlice;
