import { Social } from '@ui/Social/Social';

import styles from './GuAuthButton.module.scss';

const GuAuthButton = ({ isRedesign }) => {
  return (
    <div className={styles.guButton}>
      <Social name='gu' alwaysActive={false} isRedesign={isRedesign} />
    </div>
  );
};

export default GuAuthButton;
