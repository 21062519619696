import { useEffect, useState } from 'react';
import { clsx } from 'clsx';

import { isIos } from 'util/media';

import { Button } from 'components/core-ui/Button/Button';
import { Icon } from 'components/core-ui/Icon/Icon';

import styles from './AddTabModal.module.scss';

export const AddTabModal = ({ onChange }) => {
  const [promptInstall, setPromptInstall] = useState(null);
  const isIOS = isIos();

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const onClick = async () => {
    if (promptInstall) {
      promptInstall.prompt();
    } else {
      window.open('/login', '_blank');
    }
  };

  return (
    <div className={clsx(styles.root, { [styles.root_ios]: isIOS })}>
      {isIOS ? (
        <div className={styles.wrapper}>
          <div
            className={styles.close}
            onClick={() => {
              onChange(false);
            }}
          >
            <Icon name='closeMobile' />
          </div>

          <div className={styles.wrapper_header}>
            <img src='/images/logo/logo_40.svg' alt='logo' />

            <p className={styles.title}>Приложение всегда под рукой</p>
          </div>

          <div className={styles.text}>
            <span>
              Нажмите на иконку <Icon name='share' /> и в меню выберите
            </span>
            <br></br>
            <span> «На экран “Домой”»</span>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.info}>у нас есть удобное приложение</div>
          <div className={styles.androidClose} onClick={() => onChange(false)}>
            <Icon name='closeMobile' />
          </div>
          <div className={styles.btns}>
            <Button className={styles.link} onClick={onClick}>
              Перейти
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
