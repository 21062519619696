import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import commonSlice from 'store/common';
import completionSlice from 'store/completion';
import { fetchProfile } from 'store/profile';
import { completeRegistrationJobAndCommunity } from 'store/registration';

import { Button } from 'components/core-ui/Button/Button';
import { Invalid } from 'components/core-ui/Invalid/Invalid';
import { Popover } from 'components/core-ui/Popover/Popover';
import { Icon } from 'components/core-ui/Icon/Icon';
import Loader from 'components/common/Loader';
import { NewJobInput } from 'components/main/profile/blocks/JobInput/NewJobInput';
import NewCompletionCommunity from 'components/completion/blocks/CompletionCommunities/NewCompletionCommunity';
import { ConfirmPhoneModal } from 'components/completion/blocks/ConfirmPhoneModal/ComfirmPhoneModal';
import { BusinessInfoModal } from 'components/completion/blocks/BusinessInfoModal/BusinessInfoModal';

import styles from './BusinessStep.module.scss';

export const BusinessStep = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);
  const loading = useSelector((state) => state.common.loading);
  const { isEditPhone } = useSelector((state) => state.registration);
  const isMainJobProfile = profile?.jobs?.filter((job) => job.isMain)[0];
  const [sended, setSended] = useState(false);
  const [communities, setCommunities] = useState([{}]);
  const [jobs, setJobs] = useState([]);
  const [isShowBusinessInfo, setIsShowBusinessInfo] = useState(false);
  const [isShowConfirmPhoneModal, setIsShowConfirmPhoneModal] = useState(false);

  const initAndSortProfileJobs = () => {
    setJobs(
      profile.jobs.length
        ? profile.jobs
            .map((j, i) => ({
              id: j.id,
              place: j.place,
              position: j.position,
              fieldsIds:
                j.fieldsIds && j.fieldsIds.length ? j.fieldsIds.map((fieldItem) => fieldItem) : [],
              // eslint-disable-next-line no-nested-ternary
              isMain: isMainJobProfile ? j.isMain : i === 0 ? true : j.isMain,
            }))
            .sort((jobA, jobB) => jobA.id - jobB.id)
        : [
            {
              place: '',
              position: '',
              fieldsIds: [],
              isMain: true,
            },
          ],
    );
  };

  const initCommunities = () => {
    profile.communities.length === 0
      ? setCommunities([{ id: -1, title: '' }])
      : setCommunities(profile.communities);
  };

  const jobChanged = (job, data, jobIndex) => {
    if (jobIndex < 0) setJobs(jobs);

    jobs.splice(jobIndex, 1, { ...job, ...data });
    setJobs([...jobs]);
  };

  const isError = () => {
    let isNull = false;
    let isLength = false;
    let isPlaceAndPosition = false;
    jobs.forEach((j) => {
      if (j.position && j.place) {
        if (j.fieldsIds && j.fieldsIds.length) {
          j.fieldsIds.forEach((id) => {
            if (!id) isNull = true;
          });
        } else {
          if (!isLength) {
            isLength = true;
          }
        }
      } else {
        if (!isPlaceAndPosition) {
          isPlaceAndPosition = true;
        }
      }
    });
    return !isNull && !isLength && !isPlaceAndPosition;
  };

  const jError = !isError() ? 'Добавьте компанию' : null;

  const changeMainJob = (i) => {
    const newIsMainJob = jobs.map((jobItem, jobIndex) => {
      if (jobIndex === i) {
        jobItem.isMain = true;
        return jobItem;
      } else {
        jobItem.isMain = false;
        return jobItem;
      }
    });

    setJobs(newIsMainJob);
  };

  const confirmProfilePhone = () => {
    setIsShowConfirmPhoneModal(true);
  };

  const formSubmitted = (ev) => {
    ev.preventDefault();
    if (jError) {
      return;
    }

    dispatch(commonSlice.actions.setLoading(true));

    const data = {
      jobs: [...jobs],
      communitiesIds: [...communities]
        .filter((community) => typeof community.id === 'number' && community.id !== -1)
        .map((community) => community.id),
      customCommunities: [...communities].filter((community) => community.__isNew__ === true),
    };

    dispatch(completeRegistrationJobAndCommunity(data));
    window.scrollTo(0, 0);

    confirmProfilePhone();
  };

  const jobDeleted = (i) => {
    const filteredJobs = jobs
      .filter((jobItem, jobIndex) => {
        if (jobIndex !== i) return jobItem;
      })
      .map((filteredJobItem, filteredJobIndex) => {
        filteredJobIndex === 0 ? (filteredJobItem.isMain = true) : (filteredJobItem.isMain = false);
        return filteredJobItem;
      });

    setJobs(filteredJobs);
  };

  const addJob = () => {
    setJobs((prevJobs) => [...prevJobs, { place: '', position: '', fieldsIds: [], isMain: false }]);
  };

  const backClicked = () => {
    dispatch(completionSlice.actions.changeRedesignStep(2));
    window.scrollTo(0, 0);
  };

  const communityChange = ({ comm, index }) => {
    setCommunities(communities.map((item, i) => (i === index ? comm : item)));
  };

  const addComm = () => {
    if (communities.length < 5) {
      setCommunities([...communities, { id: -1, title: '' }]);
    }
  };

  const onRemoveEmptyComm = (index) => {
    setCommunities((prev) => {
      const prevComm = [...prev];
      prevComm.splice(index, 1);
      return prevComm;
    });
  };

  const onRemoveComm = (id) => {
    setCommunities(
      communities.filter((item) => item.id !== id)?.length > 0
        ? communities.filter((item) => item.id !== id)
        : [{}],
    );
  };

  useEffect(() => {
    if (!profile) dispatch(fetchProfile());
  }, [profile, dispatch]);

  useEffect(() => {
    if (profile) {
      initAndSortProfileJobs();
      initCommunities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  if (loading) {
    return (
      <div className={styles.content_loader}>
        <Loader isRegister />
      </div>
    );
  }

  return (
    <div className={styles.content}>
      <form onSubmit={formSubmitted} className={styles.form}>
        <div className={styles.sections}>
          <div>
            <div className={styles.sectionTitle}>Деятельность</div>

            <div>
              <div className={styles.section}>
                {jobs.map((job, i) => (
                  <div key={`job_${i}`} className={styles.jobInput}>
                    <NewJobInput
                      index={i}
                      job={job}
                      onChange={(data) => jobChanged(job, data, i)}
                      onDelete={() => jobDeleted(i)}
                      showButtons={jobs.length > 1}
                      isMainJob={job.isMain}
                      changeMainJob={() => changeMainJob(i)}
                      isLast={i === (jobs.length && jobs.length - 1)}
                      isDefaultStyle
                      jobsLength={jobs.length}
                      jError={jError}
                    />
                  </div>
                ))}

                {sended && jError && (
                  <Invalid>
                    {jError}
                    <br />
                    <br />
                    <br />
                  </Invalid>
                )}

                {jobs.length < 5 && (
                  <div className={styles.add} onClick={addJob}>
                    <span>Добавить блок</span>
                    <Icon name='circlePlus' size='medium' />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <div className={styles.sectionTitle} data-always-active='active'>
              <span>бизнес-сообщества</span>
              <div onClick={() => setIsShowBusinessInfo(true)}>
                <Icon name='attentionCircleRedesign' size='medium' />
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.communityList}>
                <div className={styles.header}>
                  <h3 className={styles.header_title}>Добавление сообщества</h3>
                  <p className={styles.header_text}>
                    Эта информация будет видна после проверки модератора <br /> в вашем профиле
                  </p>
                </div>

                {!!communities.length &&
                  communities?.map((comm, index) => {
                    return (
                      <div key={`community_${index}`} className={styles.communityItem}>
                        <NewCompletionCommunity
                          key={index}
                          value={comm}
                          selectedCommunities={communities}
                          indexComm={index}
                          onChange={(comm) => communityChange({ comm, index })}
                          onRemoveEmptyComm={onRemoveEmptyComm}
                          onRemove={onRemoveComm}
                        />
                      </div>
                    );
                  })}
              </div>
              {communities.length < 5 && (
                <div className={styles.add} onClick={addComm}>
                  <span>Добавить блок</span>
                  <Icon name='circlePlus' size='medium' />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.back} onClick={backClicked}>
            <span>Назад</span>
          </div>
          <Popover
            isActive={jError || false}
            isAlignTop
            posX={350}
            posY={-25}
            tooltip='Для продолжения необходимо заполнить все поля'
          >
            <div>
              <Button width='normal' type='submit' disabled={jError} className={styles.submit}>
                Далее
              </Button>
            </div>
          </Popover>
        </div>
      </form>

      {isShowBusinessInfo && <BusinessInfoModal closeModal={() => setIsShowBusinessInfo(false)} />}

      {isShowConfirmPhoneModal && (
        <ConfirmPhoneModal
          title={!isEditPhone ? 'Подтверждение номера телефона' : 'Изменение номера'}
          isEdit={isEditPhone}
          closeModal={() => setIsShowConfirmPhoneModal(false)}
        />
      )}
    </div>
  );
};
