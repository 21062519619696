import PropTypes from 'prop-types';

import styles from './Radio.module.scss';

const Radio = ({ value, options, onChange, defaultStyle = '' }) => {
  const setValue = (value) => {
    onChange(value);
  };

  return (
    <div className={styles.radio} data-style={defaultStyle}>
      {options?.map((option) => (
        <div
          className={styles.option}
          onClick={() => setValue(option.value)}
          data-selected={value === option.value ? 'selected' : ''}
          key={option.value}
        >
          <div className={styles.optionIcon}>
            <span></span>
          </div>
          <div className={styles.optionTitle}>{option.title}</div>
        </div>
      ))}
    </div>
  );
};

Radio.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  onChange: PropTypes.func,
  defaultStyle: PropTypes.string,
};

export { Radio };
