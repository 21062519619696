import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { useEffect, useRef } from 'react';
import { clsx } from 'clsx';

import { Icon } from 'components/core-ui/Icon/Icon';

import styles from '../TextInput/TextInput.module.scss';

const TextInput = ({
  onOverlayClick,
  capital,
  placeholderMobile = '',
  error,
  search,
  invisible,
  isTextArea,
  isScrollable,
  isClearable,
  onClose,
  type,
  refInput,
  label,
  classN,
  isArray,
  isSMS,
  isEmptyPlaceholder = false,
  textareaPlusSize = 0,
  ...props
}) => {
  const isTablet = useMediaQuery('(max-width: 1024px)');
  const invalid = props.invalid;
  const ref = useRef();
  const textareaRef = useRef(null);
  const inputProps = { ...props };
  const { onChange } = { ...props };
  delete inputProps.invalid;

  // eslint-disable-next-line no-nested-ternary
  inputProps.placeholder = isTablet
    ? search
      ? inputProps.placeholder
      : ' '
    : inputProps.placeholder;

  if (refInput) {
    inputProps.ref = refInput;
  }

  placeholderMobile = isTablet ? placeholderMobile : null;

  const classesContainer = clsx(styles._, {
    [styles.textInput]: !search,
    [styles.searchInput]: search,
    [styles.textInput_tablet]: isTablet,
    [styles.invisible]: isTablet && invisible,
    [styles.textInput_redesignError]: error,
    [styles.textInput_redesign]: classN,
    [styles.textInput_codeField]: isSMS,
  });

  const formatLabel = (labelValue) => {
    return isArray ? labelValue.slice(0, -2) : labelValue;
  };

  const textareaInputHandler = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight + textareaPlusSize}px`;
    }
  };

  useEffect(() => {
    if (isTextArea && inputProps.value.length) {
      textareaRef.current.focus();
      textareaInputHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputProps.value]);

  return (
    <div
      className={classesContainer}
      data-capital={capital ? 'capital' : ''}
      data-invalid={invalid ? 'invalid' : ''}
      data-type={type}
    >
      {search && (
        <div className={styles.search}>
          <Icon name='search'></Icon>
        </div>
      )}

      {/* eslint-disable-next-line no-nested-ternary */}
      {type === 'tel' ? (
        <IMaskInput
          mask={'+{7}(000)000-00-00'}
          lazy={false}
          placeholderChar='_'
          ref={ref}
          unmask={true}
          onAccept={(value) => value && onChange(value)}
          {...inputProps}
        />
      ) : !!isTextArea && type !== 'tel' ? (
        <>
          <textarea ref={textareaRef} {...inputProps} id={label} />
          {label && <label htmlFor={label}>{formatLabel(label)}</label>}
        </>
      ) : (
        <>
          <input {...inputProps} type={type} id={label} />
          {label && <label htmlFor={label}>{formatLabel(label)}</label>}
        </>
      )}

      {isTablet && !isEmptyPlaceholder && (
        <section data-invalid={error ? 'invalid' : ''} className={styles.placeholder}>
          {error ? error : placeholderMobile}
        </section>
      )}

      {inputProps.disabled && (
        <div onClick={onOverlayClick && onOverlayClick} className={styles.overlay}>
          {isTextArea && isScrollable && inputProps.value}
          {isClearable && props.value && (
            <div className={styles.clear} onClick={onClose}>
              <Icon name='close'></Icon>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

TextInput.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off']),
  capital: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  invalid: PropTypes.bool,
  invisible: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onOverlayClick: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderMobile: PropTypes.string,
  search: PropTypes.bool,
  style: PropTypes.any,
  type: PropTypes.oneOf(['text', 'tel', 'email', 'number']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextInput.defaultProps = {
  type: 'text',
  invalid: false,
  placeholder: 'Введите текст...',
  value: '',
  onChange: () => {},
};

export { TextInput };
