import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clsx } from 'clsx';

import { fetchProfile } from 'store/profile';
import registrationSlice, {
  sendSMSRegistrationCode,
  completeRegistrationVerifyPhone,
} from 'store/registration';

import { Button } from 'components/core-ui/Button/Button';
import Loader from 'components/common/Loader';
import { ConfirmPhoneModal } from 'components/completion/blocks/ConfirmPhoneModal/ComfirmPhoneModal';
import { PinCodeInput } from 'components/core-ui/PinCodeInput/PinCodeInput';
import { ManagerCallStep } from '../ManagerCallStep/ManagerCallStep';

import styles from './SMSStep.module.scss';

const initDigits = ['', '', '', ''];

export const SMSStep = ({ isSecurity = false, onClose }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);
  const loading = useSelector((state) => state.common.loading);
  const { incorrectCode, isShowIncorrectTooltip, isManagerCallStep } = useSelector(
    (state) => state.registration,
  );
  const [digits, setDigits] = useState(initDigits);
  const [timerValue, setTimerValue] = useState(30);
  const [isShowChangePhoneModal, setIsShowChangePhoneModal] = useState(false);
  const [title, setTitle] = useState('Введите последние 4 цифры входящего номера');
  const [subTitle, setSubtitle] = useState('Звоним вам на');
  const [isSMS, setIsSMS] = useState(false);

  const sendSMSCode = () => {
    dispatch(sendSMSRegistrationCode());
    setTitle('Введите код из смс');
    setSubtitle('Отправили на номер');
    setIsSMS(true);
    setTimerValue(30);
  };

  const verifyPhone = () => {
    if (incorrectCode) {
      dispatch(registrationSlice.actions.setIsShowIncorrectTooltip(true));
    } else {
      const correctCode = digits.join('');

      dispatch(completeRegistrationVerifyPhone({ code: Number(correctCode) }));

      if (isSecurity) onClose(true);
    }
  };

  useEffect(() => {
    if (!profile) dispatch(fetchProfile());
  }, [profile, dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timerValue > 0) {
        setTimerValue(timerValue - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [timerValue]);

  useEffect(() => {
    if (digits.some((el) => el !== '')) {
      dispatch(registrationSlice.actions.setIsShowIncorrectTooltip(false));
      dispatch(registrationSlice.actions.setIsIncorrectCode(false));
    }
  }, [digits, dispatch]);

  useEffect(() => {
    if (isManagerCallStep) setTimerValue(0);
  }, [isManagerCallStep]);

  if (loading) {
    return (
      <div className={styles.content_loader}>
        <Loader isRegister />
      </div>
    );
  }

  if (isManagerCallStep && !isSecurity) {
    return <ManagerCallStep />;
  }

  return (
    <div
      className={clsx(styles.content, {
        [styles.content_small]: digits.every((el) => el === ''),
        [styles.content_smallSMS]: isSMS,
        [styles.content_security]: isSecurity,
      })}
    >
      <div
        className={clsx(styles.header, {
          [styles.header_small]: isSMS,
          [styles.header_security]: isSecurity,
        })}
      >
        <span
          className={clsx(styles.header_title, {
            [styles.header_title_small]: isSMS,
            [styles.header_title_security]: isSecurity,
          })}
        >
          {title}
        </span>
        <span
          className={clsx(styles.header_phone, { [styles.header_phone_security]: isSecurity })}
        >{`${subTitle} ${profile && profile.phone}`}</span>
      </div>

      <div className={styles.body}>
        <PinCodeInput digits={digits} incorrectCode={incorrectCode} changeHandler={setDigits} />

        <div className={styles.controller}>
          {timerValue > 0 && digits.every((el) => el === '') && (
            <Button className={styles.reverseTimer}>
              {`00:${timerValue.toString().padStart(2, '0')}`}
            </Button>
          )}

          {timerValue === 0 && digits.every((el) => el === '') && (
            <Button className={styles.repeatCode} onClick={sendSMSCode}>
              {!isSMS ? 'Отправить код в СМС' : 'Отправить код повторно'}
            </Button>
          )}

          {digits.some((el) => el !== '') && (
            <Button className={styles.repeatCode} onClick={verifyPhone}>
              Готово
            </Button>
          )}

          {digits.some((el) => el !== '') ||
            (timerValue === 0 && (
              <span className={styles.changePhone} onClick={() => setIsShowChangePhoneModal(true)}>
                Изменить номер
              </span>
            ))}

          {digits.some((el) => el !== '') && isSMS && (
            <span className={styles.changePhone} onClick={() => setIsShowChangePhoneModal(true)}>
              Изменить номер
            </span>
          )}
        </div>
      </div>

      {isShowChangePhoneModal && (
        <ConfirmPhoneModal
          isEdit
          title='Изменение номера'
          closeModal={() => setIsShowChangePhoneModal(false)}
        />
      )}

      {isShowIncorrectTooltip && <div className={styles.codeError}>Неверный код</div>}
    </div>
  );
};
