import { configureStore } from '@reduxjs/toolkit';

import authSlice from './auth';
import businessCommunitySlice from './businessCommunity';
import calendarSlice from './calendar';
import clubSlice from './club';
import commonSlice from './common';
import completionSlice from './completion';
import feedbackSlice from './feedback';
import messengerSlice from './messenger';
import profileSlice from './profile';
import recommendFormSlice from 'components/services/hrsm/forms/RecommendForm/recommendForm.slice';
import registrationSlice from './registration';
import servicesSlice from './services';
import subscriptionSlice from './subscription';
import systemSlice from './system';
import vacancySlice from 'components/services/hrsm/pages/VacancyPage/Vacancy.slice';
import profileUISlice from './profileUI';
import karmaSlice from './karma';
import notificationsSlice from './notifications';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    businessCommunity: businessCommunitySlice.reducer,
    calendar: calendarSlice.reducer,
    club: clubSlice.reducer,
    common: commonSlice.reducer,
    completion: completionSlice.reducer,
    feedback: feedbackSlice.reducer,
    messenger: messengerSlice.reducer,
    profile: profileSlice.reducer,
    recommend: recommendFormSlice.reducer,
    registration: registrationSlice.reducer,
    services: servicesSlice.reducer,
    subscription: subscriptionSlice.reducer,
    system: systemSlice.reducer,
    vacancy: vacancySlice.reducer,
    uiSlice: profileUISlice.reducer,
    karma: karmaSlice.reducer,
    notifications: notificationsSlice.reducer,
  },
});

export default store;
