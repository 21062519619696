import React from 'react';
import { components } from 'react-select';
import { Icon } from 'components/core-ui/Icon/Icon';

import styles from '../../../main/profile/blocks/JobInput/NewJobInput.module.scss';
import componentsStyles from './Components.module.scss';

const { ValueContainer, Placeholder, IndicatorsContainer } = components;

export const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {children.map((child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );
};

export const CustomDropdownIndication = ({ ...props }) => {
  return (
    <IndicatorsContainer {...props}>
      {props.hasValue && props.isClearable ? (
        <div className={componentsStyles.delete} onClick={() => props.clearValue()}>
          <Icon name='dropdownArrowClose' size='big' />
        </div>
      ) : (
        <>
          {props.selectProps.menuIsOpen ? (
            <div className={componentsStyles.delete}>
              <Icon name='dropdownArrowUp' size='big' />
            </div>
          ) : (
            <div className={componentsStyles.delete}>
              <Icon name='dropdownArrowDown' size='big' />
            </div>
          )}
        </>
      )}
    </IndicatorsContainer>
  );
};

export const CustomControl = ({ children, ...props }) => (
  <components.Control {...props}>
    <div className={styles.search}>
      <Icon name='search'></Icon>
    </div>
    {children}
  </components.Control>
);

export const CustomFormatOptionLabel = () => {
  return (
    <div className={componentsStyles.optionLabel}>
      <div>Такого сообщества еще нет в списке</div>
      <span className={componentsStyles.optionLabel_link}>добавить</span>
    </div>
  );
};

export const CustomMenu = (props) => {
  const valueLength = props.getValue().length || 0;
  return <components.Menu {...props}>{valueLength < 3 ? props.children : null}</components.Menu>;
};
