import PropTypes from 'prop-types';

import styles from '../Tooltip/Tooltip.module.scss';

const Tooltip = ({ text, style, size, isAlignTop = false }) => {
  return (
    <div
      className={styles.tooltip + `${isAlignTop ? ' ' + styles.alignTop : ''}`}
      style={style}
      data-size={size}
    >
      {text}
      <span className={styles.triangle}></span>
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.any,
  size: PropTypes.oneOf(['small', 'big', 'normal', 'large']),
  isAlignTop: PropTypes.bool,
};

Tooltip.defaultProps = {
  text: '',
  size: 'small',
};

export { Tooltip };
