import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';

import { signInWithGoogle } from '@store/auth';
import { Social } from '@ui/Social/Social';

import styles from './GoogleAuthButton.module.scss';

const GoogleAuthButton = ({ onDone, isLogin, isRedesign }) => {
  const dispatch = useDispatch();
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => onSuccess({ accessToken: tokenResponse.access_token }),
  });

  const onSuccess = async (response) => {
    onDone();
    dispatch(signInWithGoogle({ response, isLogin }));
  };

  return (
    <div className={styles.googleAuthButton}>
      <div onClick={login}>
        <Social name='google' isRedesign={isRedesign} />
      </div>
    </div>
  );
};

export default GoogleAuthButton;
