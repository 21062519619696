import rem from '@util/rem';

const selectStylesMobile = {
  container: (base) => ({ ...base, minHeight: '55vh' }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (base) => ({
    ...base,
    position: `relative`,
    border: `none`,
    borderRadius: `0`,
    boxShadow: `unset`,
  }),
  dropdownIndicator: (styles) => ({ ...styles, display: `none` }),
  control: (styles) => {
    return {
      ...styles,
      background: '#EFEFEF',
      border: `none !important`,
      boxShadow: `unset !important`,
      borderRadius: `6px`,
      padding: 2,
      width: '100%',
      outline: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '110%',
      color: '#303030',
      minHeight: '32px',
      textTransform: 'lowercase',
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      zIndex: 10000000000000000,
      padding: '14px',
      background: state.isFocused || state.isSelected ? '#F9F9F9' : provided.background,
      color: provided.background,
      fontSize: '14px',
      lineHeight: '19px',
      '&:hover': {
        background: '#F9F9F9',
      },
      '&:active': {
        background: '#F9F9F9',
      },
    };
  },
  valueContainer: (styles) => ({
    ...styles,
    padding: 5,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: `none` }),
  multiValue: (styles) => ({
    ...styles,
    display: 'flex',
  }),
  placeholder: (styles) => ({
    ...styles,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '110%',
    color: '#AFB3CB',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '80vh',
    paddingBottom: '80px',
  }),
};

const redesignSelectMobileStyles = {
  container: (base) => ({ ...base, minHeight: '55vh' }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (base) => ({
    ...base,
    position: `relative`,
    border: `none`,
    borderRadius: `0`,
    boxShadow: `unset`,
    marginTop: '0px !important',
    height: '300px',
  }),
  dropdownIndicator: (styles) => ({ ...styles, display: `none` }),
  control: (styles) => {
    return {
      ...styles,
      background: '#EFEFEF',
      border: `none !important`,
      boxShadow: `unset !important`,
      borderRadius: `6px`,
      padding: 2,
      width: '100%',
      height: '32px',
      outline: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '110%',
      color: '#303030',
      minHeight: '32px',
      textTransform: 'lowercase',
    };
  },
  option: (provided) => {
    return {
      ...provided,
      height: '40px',
      zIndex: 10000000000000000,
      padding: '12px 8px',
      background: '#fff',
      color: provided.background,
      fontSize: '14px',
      lineHeight: '19px',

      '&:hover': {
        background: '#F9F9F9',
      },
      '&:active': {
        background: '#F9F9F9',
      },
    };
  },
  valueContainer: (styles) => ({
    ...styles,
    padding: 5,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: `none` }),
  multiValue: (styles) => ({
    ...styles,
    display: 'flex',
  }),
  placeholder: (styles) => ({
    ...styles,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '110%',
    color: '#AFB3CB',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '300px',
    paddingBottom: '80px',

    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),
};

const redesignSelectTabletStyles = {
  container: (base) => ({ ...base, minHeight: '55vh' }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (base) => ({
    ...base,
    position: `relative`,
    border: `none`,
    borderRadius: `0`,
    boxShadow: `unset`,
    marginTop: '0px !important',
  }),
  dropdownIndicator: (styles) => ({ ...styles, display: `none` }),
  control: (styles) => {
    return {
      ...styles,
      background: '#EFEFEF',
      border: `none !important`,
      boxShadow: `unset !important`,
      borderRadius: `6px`,
      padding: 2,
      width: '100%',
      height: '40px',
      outline: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '110%',
      color: '#303030',
      minHeight: '32px',
      textTransform: 'lowercase',
    };
  },
  option: (provided) => {
    return {
      ...provided,
      height: '48px',
      zIndex: 10000000000000000,
      padding: '16px 8px',
      background: '#fff',
      color: provided.background,
      fontSize: '14px',
      lineHeight: '115%',

      '&:hover': {
        background: '#F9F9F9',
      },
      '&:active': {
        background: '#F9F9F9',
      },
    };
  },
  valueContainer: (styles) => ({
    ...styles,
    padding: 5,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: `none` }),
  multiValue: (styles) => ({
    ...styles,
    display: 'flex',
  }),
  placeholder: (styles) => ({
    ...styles,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '110%',
    color: '#AFB3CB',
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '700px',
    padding: '16px 0 80px',
  }),
};

export { selectStylesMobile, redesignSelectMobileStyles, redesignSelectTabletStyles };
