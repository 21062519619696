import { createRef, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { clsx } from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { useMediaQuery } from '@mui/material';

import { Button } from 'components/core-ui/Button/Button';
import { DrawerBlock } from 'components/core-ui/DrawerBlock/DrawerBlock';
import { Icon } from 'components/core-ui/Icon/Icon';

import 'cropperjs/dist/cropper.css';
import styles from './ImageCropper.module.scss';

export const RedesignImageCropper = ({ image, onCancel, onDone, changePhoto }) => {
  const cropperRef = createRef();
  const drawerBlockRef = useRef(null);
  const isTablet = useMediaQuery('(max-width: 1024px)');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [isDrawerBlock, setIsDrawerBlock] = useState(true);

  const getCropData = () => {
    if (typeof cropperRef.current !== 'undefined') {
      const canvas = cropperRef?.current?.cropper.getCroppedCanvas();
      canvas.toBlob(
        (blob) => {
          onDone(blob);
        },
        'image/jpeg',
        0.9,
      );
    }
  };

  const closeClicked = () => {
    let closeEvent = new CustomEvent('closeDrawerBlock');
    document.dispatchEvent(closeEvent);

    setIsDrawerBlock(false);
    setTimeout(() => {
      onCancel();
    }, 300);
  };

  const setImageSize = () => {
    if (isMobile) return { width: 328, height: 350 };
    if (isTablet) return { width: 592, height: 568, display: 'flex', justifyContent: 'center' };
  };

  return (
    <>
      {isTablet ? (
        <div className={styles.imageCropper_mobile}>
          <CSSTransition in={isDrawerBlock} nodeRef={drawerBlockRef} timeout={250} unmountOnExit>
            <div ref={drawerBlockRef}>
              <DrawerBlock title={'Обрежьте фото'} closeButton onClose={closeClicked} isRedesign>
                <div className={styles.content}>
                  <div className={styles.scrollable}>
                    <div className={styles.container}>
                      <div className={clsx(styles.image, { [styles.imageBG]: image })}>
                        <Cropper
                          ref={cropperRef}
                          style={setImageSize()}
                          aspectRatio={1}
                          initialAspectRatio={1}
                          preview='.img-preview'
                          src={image}
                          viewMode={2}
                          minCropBoxHeight={120}
                          minCropBoxWidth={120}
                          background={false}
                          responsive={true}
                          autoCropArea={1}
                          checkOrientation={false}
                          guides={false}
                          zoomOnWheel={false}
                          movable={false}
                          zoomable
                        />
                      </div>

                      <div className={styles.done}>
                        <Button
                          outline={true}
                          onClick={changePhoto}
                          className={styles.reselectPhoto}
                        >
                          Заменить фото
                        </Button>
                        <Button onClick={getCropData} className={styles.save}>
                          Сохранить и продолжить
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </DrawerBlock>
            </div>
          </CSSTransition>
        </div>
      ) : (
        <div className={styles.imageCropper}>
          <div className={styles.backdrop} />
          <div className={styles.content}>
            <div className={styles.scrollable}>
              <span className={styles.cancel} onClick={onCancel}>
                <Icon name='close' />
              </span>

              <div>
                <div className={styles.label}>Фото профиля</div>

                <div className={clsx(styles.image, { [styles.imageBG]: image })}>
                  <Cropper
                    ref={cropperRef}
                    style={{ height: 400, width: '100%' }}
                    aspectRatio={1}
                    initialAspectRatio={1}
                    preview='.img-preview'
                    src={image}
                    viewMode={2}
                    minCropBoxHeight={120}
                    minCropBoxWidth={120}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={false}
                    zoomOnWheel={true}
                  />
                </div>

                <div className={styles.done}>
                  <Button outline={true} onClick={changePhoto} className={styles.reselectPhoto}>
                    Заменить фото
                  </Button>

                  <Button onClick={getCropData}>Сохранить и продолжить</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
