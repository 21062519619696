import { createSlice } from '@reduxjs/toolkit';

export const servicesSlice = createSlice({
  name: 'services',
  initialState: {
    services: [
      { name: 'system', notifications: 0, title: 'системные инициативы' },
      { name: 'club', notifications: 0, title: 'картирование' },
      { name: 'calendar', notifications: 0, title: 'календарь событий' },
      { name: 'hrsm', notifications: 0, title: 'маркетплейс кадров' },
    ],
    isShowServices: false,
  },
  reducers: {
    setIsShowServices: (state, { payload }) => {
      state.isShowServices = payload;
    },
  },
});

export default servicesSlice;
