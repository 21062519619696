import { createSlice } from '@reduxjs/toolkit';

import axios from 'util/axios';
import { fetchProfile } from './profile';

export const profileUISlice = createSlice({
  name: 'profileUI',
  initialState: { karmaDescription: false },
  reducers: {
    setIsShowKarmaDescription: (state, { payload }) => {
      state.karmaDescription = payload;
    },
  },
});

export const updateKarmaDescription = (data) => async (dispatch) => {
  const response = await axios.put('/profile/update-ui-state/', { karmaDescription: data });
  dispatch(profileUISlice.actions.setIsShowKarmaDescription(response.data));
  dispatch(fetchProfile());
};

export default profileUISlice;
