import { createSlice } from '@reduxjs/toolkit';

import axios from '@util/axios';
import { specialistLevelItems } from '../../blocks/VacancyFilter/filter.utils';

export const vacancySlice = createSlice({
  name: 'vacancy',
  initialState: {
    profSpheres: [],
    filterOptions: undefined,
    vacancies: [],
    myVacancies: [],
    user: null,
  },
  reducers: {
    setFilterOptions(state, action) {
      const vacancies = action.payload.vacancies;
      const profSpheres = action.payload.profSpheres;

      state.filterOptions = [
        {
          label: 'отрасль',
          id: 'industry',
          items: profSpheres.map((sphere) => ({
            ...sphere,
            amount: vacancies.reduce(
              (accum, vacancy) =>
                vacancy.fields.find((field) => field.id === sphere.id) ? accum + 1 : accum,
              0,
            ),
          })),
        },
        {
          label: 'уровень специалиста',
          id: 'specialist level',
          items: specialistLevelItems.map((item) => ({
            ...item,
            amount: vacancies.reduce(
              (accum, vacancy) => (vacancy.vacancyLevel === item.id ? accum + 1 : accum),
              0,
            ),
          })),
        },
      ];
    },
    setProfSpheres(state, action) {
      state.profSpheres = action.payload;
    },
    setVacancies(state, action) {
      state.vacancies = action.payload;
    },
    setMyVacancies(state, action) {
      state.myVacancies = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const { setVacancies, setFilterOptions, setProfSpheres, setMyVacancies } =
  vacancySlice.actions;

export const fetchVacancies = (bizFields, vacancyLevel, userId) => async (dispatch, getState) => {
  const state = getState();
  // if (state.vacancy.vacancies.length) return;
  const response = await axios.get('/hrsm/vacancies/', {
    params: { bizFields, vacancyLevel, userId: userId || state.vacancy.userId },
  });

  dispatch(setVacancies(response.data.vacancies));
  dispatch(vacancySlice.actions.setUser(response.data.user));

  let profSpheres = state.vacancy.profSpheres;
  if (!profSpheres.length) {
    const profSpheresResponce = await axios.get('/profile/job-fields/');
    dispatch(setProfSpheres(profSpheresResponce.data));
    profSpheres = profSpheresResponce.data;
  }

  dispatch(setFilterOptions({ profSpheres, vacancies: response.data.vacancies }));
};

export const fetchProfSpheres = () => async (dispatch, getState) => {
  const state = getState();
  if (state.vacancy.profSpheres.length) return;
  const response = await axios.get('/hrsm/prof-spheres/');
  dispatch(setProfSpheres(response.data.profSpheres));
};

export const fetchMyVacancies = (id, isArchive) => async (dispatch) => {
  const response = await axios.get(`/hrsm/vacancies?userId=${id}&isArchive=${isArchive}`);
  dispatch(setMyVacancies(response.data.vacancies));
};

export const archiveMyVacancy = (vacancyId, userId, isArchive) => async (dispatch) => {
  await axios.patch(`/hrsm/vacancies/${vacancyId}/archive/`);

  dispatch(fetchMyVacancies(userId, isArchive));
};

export const unarchiveMyVacancy = (vacancyId, userId, isArchive) => async (dispatch) => {
  await axios.patch(`/hrsm/vacancies/${vacancyId}/unarchive/`);

  dispatch(fetchMyVacancies(userId, isArchive));
};

export const restoreMyVacancy = (vacancyId, userId, isArchive) => async (dispatch) => {
  await axios.patch(`/hrsm/vacancies/${vacancyId}/restore/`);

  dispatch(fetchMyVacancies(userId, isArchive));
};

export const deleteMyVacancy = (vacancyId, userId) => async (dispatch) => {
  await axios.delete(`/hrsm/vacancies/${vacancyId}/`);
  dispatch(fetchMyVacancies(userId, true));
};

export default vacancySlice;
