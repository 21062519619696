import { clsx } from 'clsx';
import { useMediaQuery } from '@mui/material';

import { Button } from 'components/core-ui/Button/Button';
import { Icon } from 'components/core-ui/Icon/Icon';

import 'react-image-crop/dist/ReactCrop.css';
import './ImageCropper.scss';
import styles from './ImageSettingModal.module.scss';

export const ImageSettingModal = (props) => {
  const { error, onClose, openImageCropper } = props;
  const isTablet = useMediaQuery('(max-width: 1024px)');

  return (
    <div className={styles._}>
      <div className={styles.backdrop}></div>
      <div className={clsx(styles.content, { [styles.content_hint]: error })}>
        <div className={styles.header}>Фото профиля</div>
        <div className={clsx(styles.note, { [styles.note_hint]: error })}>
          {error && (
            <div className={styles.hint}>
              <div className={styles.hint_icon}>
                <Icon name='warning' size={!isTablet ? 'very-big' : 'big'} />
              </div>

              <div className={styles.warning}>
                <span className={styles.warning_title}>Не удалось загрузить файл</span>
                <span className={styles.warning_size}>{error}</span>
              </div>
            </div>
          )}

          {!isTablet ? (
            <div className={clsx(styles.note_photo, { [styles.note_photoHint]: error })}>
              Загрузите ваше фото в формате PNG, JPG, JPEG, BMP и размером не более 10 МБ
            </div>
          ) : (
            <div className={clsx(styles.note_photo, { [styles.note_photoHint]: error })}>
              Загрузите ваше фото в формате PNG, JPG, JPEG, BMP <br /> и размером не более 10 МБ
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <Button
            className={styles.footer_checkBtn}
            blockMobile
            height={'big'}
            onClick={() => {
              openImageCropper();
            }}
          >
            Выбрать файл
          </Button>
        </div>
        <div className={styles.close} onClick={onClose}>
          <Icon size={isTablet ? 'big' : 'normal'} name={isTablet ? 'closeMobile' : 'close'} />
        </div>
      </div>
    </div>
  );
};
