import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import { useMediaQuery } from '@mui/material';

import urls from 'settings/urls.yaml';

import completionSlice from 'store/completion';
import { logoutRedesign } from 'store/auth';

import { useUsers } from 'hooks/useUsers';

import Logo from 'components/common/Logo';
import { RegisterSwitcher } from 'components/landing-page/components/Switcher/Switcher';
import { RegisterList } from 'components/landing-page/components/RegisterList/RegisterList';
import { RegisterStep } from 'components/landing/pages/RegisterPage/RegisterStep/RegisterStep';
import { ContactsStep } from 'components/landing/pages/RegisterPage/ContactsStep/ContactsStep';
import { BusinessStep } from 'components/landing/pages/RegisterPage/BusinessStep/BusinessStep';
import { SMSStep } from 'components/landing/pages/RegisterPage/SMSStep/SMSStep';
import { RegisterPageMobile } from 'components/landing/pages/RegisterPageMobile/RegisterPageMobile';

import styles from './RegisterPage.module.scss';

const RegisterPage = () => {
  const isTablet = useMediaQuery('(max-width: 1024px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUsers();
  const redesignStep = useSelector((state) => state.completion.redesignStep);
  const { isManagerCallStep, isRegistrationSuccess } = useSelector((state) => state.registration);

  const { isServices } = useSelector((state) => state.completion);

  const onAuthDone = () => {
    !isTablet
      ? dispatch(completionSlice.actions.changeRedesignStep(2))
      : dispatch(completionSlice.actions.changeRedesignStep(5));
  };

  const registerRightClasses = clsx(styles.right, {
    [styles.right_contacts]: redesignStep === 2,
    [styles.right_business]: redesignStep === 3,
    [styles.right_sms]: redesignStep === 4,
    [styles.right_call]: isManagerCallStep,
    [styles.right_mobileRules]: redesignStep === 5,
  });

  useEffect(() => {
    if (localStorage.getItem('redirectFromLogin') === 'true')
      dispatch(completionSlice.actions.changeRedesignStep(2));
  }, [dispatch]);

  useEffect(() => {
    if (isRegistrationSuccess) navigate('/lead/account/');
  }, [isRegistrationSuccess, navigate]);

  if (user && user.isCompleted) {
    if (isServices) {
      return <Navigate to={urls.main.base + urls.main.services.base} />;
    } else {
      return <Navigate to={urls.main.base} />;
    }
  }

  if (isTablet) {
    return (
      <RegisterPageMobile
        redesignStep={redesignStep}
        registerRightClasses={registerRightClasses}
        onAuthDone={onAuthDone}
      />
    );
  }

  return (
    <>
      <div className={styles.registerPage}>
        <div className={styles.left}>
          <div className={styles.left_header}>
            <Logo isRedesign src='/images/logo/reLogo.svg' />
          </div>

          <div className={styles.left_progress}>
            <RegisterSwitcher step={redesignStep} />
            <RegisterList />
          </div>

          {redesignStep !== 1 && (
            <div
              className={styles.exit}
              onClick={() => {
                dispatch(logoutRedesign());
                dispatch(completionSlice.actions.changeRedesignStep(1));
              }}
            >
              Выход
            </div>
          )}

          <div className={styles.left_bg}></div>
        </div>
        <div className={registerRightClasses}>
          {redesignStep === 1 && <RegisterStep onAuthDone={onAuthDone} />}
          {redesignStep === 2 && <ContactsStep />}
          {redesignStep === 3 && <BusinessStep />}
          {redesignStep === 4 && <SMSStep />}
          {!isManagerCallStep && !isTablet && <div className={styles.empty}></div>}
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
