export const stringToOption = (str, isValidate = false) => {
  if (!str || (isValidate && str == ' ')) return null;
  if (
    isValidate &&
    str.split('|')[0] !== 'undefined' &&
    str.split('|')[0] !== 'NaN' &&
    str.split('|')[0] !== 'null' &&
    (str.split('|')[1] == 'undefined' || str.split('|')[1] == 'NaN' || str.split('|')[1] == 'null')
  ) {
    return {
      label: str.split('|')[0],
      value: str.split('|')[0],
    };
  }
  if (
    isValidate &&
    (str.split('|')[0] == 'undefined' ||
      str.split('|')[0] == 'NaN' ||
      str.split('|')[1] == 'undefined' ||
      str.split('|')[1] == 'NaN')
  ) {
    return null;
  }
  return {
    label: str.split('|')[0],
    value: isValidate ? str.split('|')[1] : +str.split('|')[1],
  };
};

export const optionToString = (option) => {
  if (!option) return null;
  return `${option.label}|${option.value}`;
};

export const tgUsernameToUrl = (str = '') => {
  return `t.me/${str.replace(/@/, '')}`;
};
