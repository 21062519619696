import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Icon } from 'components/core-ui/Icon/Icon';
import { DrawerBlockHeader } from '../DrawerBlockHeader/DrawerBlockHeader';

import styles from './DrawerBlock.module.scss';

const DrawerBlock = ({
  title,
  onClose,
  closeButton,
  children,
  close,
  subtitle,
  isRedesign,
  isProfile = false,
  isSmall = false,
  isProfileVerification = false,
  contentClassName,
  closeClassName,
}) => {
  const [active, setActive] = useState(true);
  const observerLoader = useRef();
  const DrawerBlockRef = useRef();

  const actionInSight = (entries) => {
    if (entries[0].isIntersecting) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  };

  useEffect(() => {
    observerLoader.current = new IntersectionObserver(actionInSight);
    observerLoader.current.observe(DrawerBlockRef.current);
  }, []);

  useEffect(() => {
    document.addEventListener('closeDrawerBlock', () => setActive(false));

    return () => document.removeEventListener('closeDrawerBlock', () => setActive(false));
  });

  const onHandleClose = () => {
    setActive(false);
    onClose();
  };

  return createPortal(
    <div className={styles.drawer_block} data-active={active ? 'active' : ''} ref={DrawerBlockRef}>
      <div className={styles.shadow} onClick={onHandleClose}></div>
      <div
        className={clsx(styles.block, {
          [styles.block_redesign_small]: isRedesign && isSmall,
          [styles.block_redesign]: isRedesign,
          [styles.block_avatar]: isProfile,
          [styles.block_verification]: isProfileVerification,
        })}
      >
        {title && !subtitle && (
          <DrawerBlockHeader
            title={title}
            isRedesign={isRedesign}
            isProfileVerification={isProfileVerification}
          ></DrawerBlockHeader>
        )}
        {title && subtitle && (
          <DrawerBlockHeader subtitle={subtitle} title={title} isRedesign={isRedesign} />
        )}
        {close && <div className={styles.close} onClick={onHandleClose} />}

        {closeButton && (
          <div className={clsx(styles.close_button, closeClassName)} onClick={onHandleClose}>
            <Icon size='big' name='closeMobile' />
          </div>
        )}
        <section
          className={clsx(styles.content, contentClassName, {
            [styles.content_redesing]: isRedesign,
            [styles.content_avatar]: isProfile,
          })}
        >
          {children}
        </section>
      </div>
    </div>,
    document.getElementById('root-overlay'),
  );
};

DrawerBlock.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  closeButton: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  close: PropTypes.bool,
  subtitle: PropTypes.string,
};

export { DrawerBlock };
