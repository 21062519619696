export function detectDeviceType() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return DeviceType.sensory;
  } else {
    return DeviceType.button;
  }
}

export const DeviceType = {
  sensory: 'sensory',
  button: 'button',
};

export const isIos = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isPwa = () => {
  return window.matchMedia('(display-mode: standalone)').matches;
};
