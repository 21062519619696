import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Icon } from '../Icon/Icon';

import styles from './Button.module.scss';

const Button = ({
  children,
  type,
  size,
  outline,
  disabled,
  icon,
  iconClassName,
  block,
  blockMobile,
  onClick,
  onMouseOver,
  onMouseOut,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  height,
  width,
  className,
  contentClassName,
  onDisabledClick,
  fullwidth,
}) => {
  const buttonClicked = (ev) => {
    if (disabled) {
      onDisabledClick();
      ev.preventDefault();
      return;
    }
    onClick(ev);
  };
  const isMobile = useMediaQuery('(max-width: 1024px)');

  return (
    <button
      type={type}
      className={`${styles.button} ${className}`}
      data-outline={outline ? 'outline' : ''}
      data-disabled={disabled ? 'disabled' : ''}
      data-size={size}
      data-height={height}
      data-width={width}
      data-fullwidth={fullwidth}
      onClick={buttonClicked}
      data-block={block || (blockMobile && isMobile) ? 'block' : ''}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      {icon && (
        <div className={clsx(styles.icon, { [iconClassName]: iconClassName })}>
          <Icon name={icon} />
        </div>
      )}
      <div className={clsx(styles.content, contentClassName)}>{children}</div>
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  type: PropTypes.oneOf(['button', 'submit']),
  size: PropTypes.oneOf(['normal', 'small', 'big', 'micro', 'large', 'medium']),
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  block: PropTypes.bool,
  blockMobile: PropTypes.bool,
  onClick: PropTypes.func,
  onDisabledClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseMove: PropTypes.func,
  height: PropTypes.oneOf(['normal', 'big']),
  width: PropTypes.oneOf(['normal', 'big']),
  className: PropTypes.string,
  fullwidth: PropTypes.bool,
};

Button.defaultProps = {
  children: '',
  type: 'button',
  size: 'normal',
  outline: false,
  disabled: false,
  fullwidth: false,
  onClick: () => {},
  onDisabledClick: () => {},
  onMouseOver: () => {},
  onMouseOut: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onMouseMove: () => {},
};

export { Button };
