import { useEffect, useRef } from 'react';
import { clsx } from 'clsx';

import styles from './PinCodeInput.module.scss';

export const PinCodeInput = (props) => {
  const { digits, incorrectCode, changeHandler } = props;
  const inputRefs = useRef([]);

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      event.preventDefault();
      if (digits[index].match(/^[0-9]$/)) {
        const newDigits = [...digits];
        newDigits[index] = '';
        changeHandler(newDigits);
      } else {
        if (index > 0) inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleChange = (index, newValue) => {
    const oldDigit = digits[index];
    const newDigit = newValue.trim().replace(oldDigit, '');
    if (newDigit < '0' || newDigit > '9') return;
    const newDigits = [...digits];
    newDigits[index] = newDigit;
    changeHandler(newDigits);
    if (index < digits.length - 1) {
      inputRefs.current[index + 1].focus();
    } else {
      inputRefs.current[index].blur();
    }
  };

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  return (
    <div className={styles.inputs}>
      {digits.map((digit, index) => (
        <input
          type='tel'
          ref={(element) => (inputRefs.current[index] = element)}
          className={clsx(styles.input, { [styles.input_incorrect]: incorrectCode })}
          key={index}
          value={digit}
          onChange={(event) => handleChange(index, event.target.value)}
          onKeyDown={(event) => handleKeyDown(index, event)}
        />
      ))}
    </div>
  );
};
