import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchUser } from 'store/auth';

export const useUsers = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) dispatch(fetchUser());
  }, [dispatch, user]);

  return user;
};
