import React, { Suspense } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import urls from 'settings/urls.yaml';

import LoaderToggler from 'components/common/LoaderToggler';
import LogoutPage from 'components/landing/pages/LogoutPage/LogoutPage';
import RegisterPage from 'components/landing/pages/RegisterPage';
import Removed from 'components/removed/Removed';
import { InvitationPage } from 'components/main/pages/invitation/InvitationPage';

const Landing = React.lazy(() => import('./components/landing/Landing'));
const MainRoutes = React.lazy(() => import('./components/main/MainRoutes'));
const Dev = React.lazy(() => import('./components/dev/Dev'));
const LandingPage = React.lazy(() => import('./components/landing-page/LandingPage'));

const AppRoutes = () => {
  const user = useSelector((state) => state.auth.user);
  const initDone = useSelector((state) => state.auth.initDone);
  const isPWA = window.matchMedia('(display-mode: standalone)').matches;
  const location = useLocation();

  if (!initDone) return null;

  const main = (
    <Suspense fallback={<LoaderToggler />}>
      <MainRoutes />
    </Suspense>
  );

  const dev = (
    <Suspense fallback={<LoaderToggler />}>
      <Dev />
    </Suspense>
  );

  const landing = (
    <Suspense fallback={<LoaderToggler />}>
      <Landing />
    </Suspense>
  );

  const removed = (
    <Suspense fallback={<LoaderToggler />}>
      <Removed />
    </Suspense>
  );

  const landingPage = (
    <Suspense fallback={<LoaderToggler />}>
      <LandingPage />
    </Suspense>
  );

  if (window.location.pathname.startsWith('/invitation/')) {
    return (
      <Routes>
        <Route path='/invitation/:userId' element={<InvitationPage />} />
      </Routes>
    );
  }

  if (location.pathname.includes('register')) {
    return (
      <Routes>
        <Route path='/register' element={<RegisterPage />} />
      </Routes>
    );
  }

  if (!user) {
    if (window.location.pathname === '/' && !isPWA) {
      return landingPage;
    } else {
      return landing;
    }
  }

  return (
    <Routes>
      <Route path={'*'} element={<Navigate to={urls.completion.base} />} />
      <Route path={urls.landing.signout} element={<LogoutPage />} />
      <Route path={urls.completion.base} element={<Navigate to='/register' />} />
      <Route path={urls.removed.base} element={removed} />
      <Route path={`${urls.main.base}*`} element={main} />
      {user.isAdmin && <Route path={`${urls.dev.base}*`} element={dev} />}
      <Route path={'/'} element={isPWA ? <Navigate to={urls.completion.base} /> : landingPage} />
      <Route path='/invitation/:userId' element={<InvitationPage />} />
    </Routes>
  );
};

export default AppRoutes;
