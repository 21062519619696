import { Icon } from 'components/core-ui/Icon/Icon';

import styles from './DropdownIndicator.module.scss';

export const DropdownIndicator = ({ index, hasValue, onDeleteCommunity }) => {
  if (index === 0 && !hasValue) {
    return (
      <div className={styles.delete}>
        <Icon name='dropdownArrowUp' size='big' />
      </div>
    );
  }

  if (index !== 0 && hasValue) {
    return (
      <div className={styles.delete}>
        <Icon name='dropdownArrowDown' size='big' />
      </div>
    );
  }

  return (
    <>
      {index === 0 && hasValue && (
        <div className={styles.delete} onClick={onDeleteCommunity}>
          <Icon name='dropdownArrowClose' size='big' />
        </div>
      )}
    </>
  );
};
