import { useMediaQuery } from '@mui/material';
import { clsx } from 'clsx';

import styles from './RegisterList.module.scss';

export const RegisterList = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1024px)');

  return (
    <div className={styles.content}>
      <div className={styles.step}>
        <span className={clsx(styles.step_title, { [styles.step_titleMobile]: isTablet })}>
          Зарегистрируйтесь
        </span>

        {!isMobile ? (
          <span
            className={clsx(styles.step_description, { [styles.step_descriptionMobile]: isTablet })}
          >
            Создайте свой профиль, чтобы получить доступ к ресурсам платформы
          </span>
        ) : (
          <span
            className={clsx(styles.step_description, { [styles.step_descriptionMobile]: isTablet })}
          >
            Создайте свой профиль, чтобы получить <br /> доступ к ресурсам платформы
          </span>
        )}
      </div>

      <div className={styles.step}>
        <span className={styles.step_title}>Оставьте контактные данные</span>
        <span className={styles.step_description}>
          {!isMobile ? (
            <>Это нужно для верификации аккаунта. Будем писать только по делу</>
          ) : (
            <>
              Это нужно для верификации аккаунта. <br />
              Будем писать только по делу
            </>
          )}
        </span>
      </div>

      <div className={styles.step}>
        <span className={styles.step_title}>Укажите информацию о вашей деятельности</span>
        {!isMobile ? (
          <span className={styles.step_description}>
            Подробный профиль вызывает больше доверия у других пользователей
          </span>
        ) : (
          <span className={styles.step_description}>
            Подробный профиль вызывает больше <br /> доверия у других пользователей
          </span>
        )}
      </div>

      <div className={styles.step}>
        <span className={styles.step_title}>Верифицируйте ваш аккаунт</span>
        {!isMobile ? (
          <span className={styles.step_description}>
            Это важно для создания безопасного пространства на платформе
          </span>
        ) : (
          <span className={styles.step_description}>
            Это важно для создания безопасного <br /> пространства на платформе
          </span>
        )}
      </div>

      <div className={styles.step}>
        <span className={styles.step_title}>Готово!</span>
        {!isMobile ? (
          <span className={styles.step_description}>
            Теперь вы можете пользоваться сервисами платформы
          </span>
        ) : (
          <span className={styles.step_description}>
            Теперь вы можете пользоваться сервисами <br /> платформы
          </span>
        )}
      </div>
    </div>
  );
};
