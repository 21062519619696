import { createSlice } from '@reduxjs/toolkit';

export const KarmaSlice = createSlice({
  name: 'karma',
  initialState: {
    isKarmaRedirect: false,
    isKarmaRedirectToLogin: false,
    isKarmaRedirectToAbout: false,
  },
  reducers: {
    setIsKarmaRedirect: (state, { payload }) => {
      state.isKarmaRedirect = payload;
    },
    setIsKarmaRedirectToLogin: (state, { payload }) => {
      state.isKarmaRedirectToLogin = payload;
    },
    setIsKarmaRedirectToAbout: (state, { payload }) => {
      state.isKarmaRedirectToAbout = payload;
    },
  },
});

export default KarmaSlice;
