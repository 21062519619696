import PropTypes from 'prop-types';

import { UseCorrectAvatar } from 'hooks/useCorrectAvatar';

import styles from './Avatar.module.scss';

const Avatar = ({ url, onClick, size, stroke }) => {
  const correctURL = UseCorrectAvatar(url);

  const avatarClicked = (ev) => {
    onClick(ev);
  };

  return (
    <div
      className={styles.avatar}
      onClick={avatarClicked}
      style={{
        padding: stroke,
        background: +stroke ? `linear-gradient(#0018a8, #7200a8)` : 'transparent',
      }}
    >
      <div
        className={styles.picture}
        data-size={size}
        style={{ backgroundImage: `url('${correctURL}')` }}
      />
    </div>
  );
};

Avatar.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([
    'small',
    'big',
    'micro',
    'karma',
    'karmaMobile',
    'icon',
    'user',
    'club',
    'normal',
    'karmaTablet',
    'giant',
    'large',
    'msgRequest',
    '40',
  ]),
  stroke: PropTypes.number,
};

Avatar.defaultProps = {
  onClick: () => {},
  size: 'normal',
};

export { Avatar };
