import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { useMediaQuery } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import { clsx } from 'clsx';

import { stringToOption } from 'util/transform';

import { Button } from 'components/core-ui/Button/Button';
import { DrawerBlock } from 'components/core-ui/DrawerBlock/DrawerBlock';
import { Icon } from 'components/core-ui/Icon/Icon';
import { Invalid } from 'components/core-ui/Invalid/Invalid';
import { Radio } from 'components/core-ui/Radio/Radio';
import {
  CustomValueContainer,
  CustomControl,
  CustomMenu,
} from 'components/core-ui/Select/components/Components';
import { TextInput } from 'components/core-ui/TextInput/TextInput';

import { redesignSelectMultiStyles } from 'components/core-ui/select-styles';
import {
  redesignSelectMobileStyles,
  redesignSelectTabletStyles,
} from 'components/core-ui/select-styles-mobile';
import styles from './NewJobInput.module.scss';

export const NewJobInput = ({
  job,
  index,
  onChange,
  onDelete,
  showButtons,
  isWide,
  invisible,
  changeMainJob,
  isMainJob,
  error,
  jobsLength,
  jError,
}) => {
  const drawerBlockRef = useRef(null);
  const inputRef = useRef(null);
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:600px)');
  const fields = useSelector((state) =>
    state.profile.jobFields.map((field) => ({
      value: field.id,
      label: field.title,
    })),
  );
  const [isDrawerBlock, setIsDrawerBlock] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const fixValues = (value) => {
    return value.map((id) =>
      typeof id === 'string' && id.includes('|') ? stringToOption(id, true) : id,
    );
  };

  const [defaultValue, setDefaultValue] = useState(fixValues(job.fieldsIds));

  const clearJobField = () => {
    setSelectedValue([]);
    onChange({ ...job, place: '', fieldsIds: [], position: '' });
  };

  const closeClicked = () => {
    setSelectedValue(defaultValue);
    setIsDrawerBlock(false);
  };

  const handleRemoveValue = (e) => {
    const buttonName = e.currentTarget.getAttribute('data-name');
    const removedValue = selectedValue.find((val) => val.label === buttonName);
    const filteredValues = selectedValue.filter((val) => val.value !== removedValue.value);

    setSelectedValue(filteredValues);
    onChange({
      ...job,
      fieldsIds: filteredValues.map((val) => {
        if (val.value !== removedValue.value) return val.value;
      }),
    });
  };

  const buttonClicked = () => {
    setDefaultValue(selectedValue);
    const closeDrawerBlock = new CustomEvent('closeDrawerBlock');
    document.dispatchEvent(closeDrawerBlock);
    setIsDrawerBlock(false);
  };

  const handleChangeCompanyName = useCallback(
    (event) => {
      onChange({ ...job, place: event.target.value });
    },
    [job, onChange],
  );

  const handleChangeActivityFields = useCallback(
    (field) => {
      setSelectedValue(field);
      onChange({ ...job, fieldsIds: field.map((val) => val.value) });
    },
    [job, onChange],
  );

  const handleChangePosition = useCallback(
    (event) => {
      onChange({ ...job, position: event.target.value });
    },
    [job, onChange],
  );

  const CaretDownIcon = () => {
    return <Icon name='dropdownArrowDown' size='big' />;
  };

  const CaretUpIcon = () => {
    return <Icon name='dropdownArrowUp' size='big' />;
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {isOpen ? <CaretUpIcon /> : <CaretDownIcon />}
      </components.DropdownIndicator>
    );
  };

  const setMobileOrTabletStyles = () => {
    if (isMobile) return redesignSelectMobileStyles;
    if (isTablet) return redesignSelectTabletStyles;
  };

  useEffect(() => {
    if (fields && job.fieldsIds) {
      setSelectedValue(fields.filter((fieldItem) => job.fieldsIds.includes(fieldItem.value)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.fieldsIds]);

  useEffect(() => {
    if (inputRef && job.fieldsIds.length === 0) {
      inputRef.current?.focus();
    }
  }, [job.fieldsIds]);

  useEffect(() => {
    isDrawerBlock && document.body.classList.add('overflowHidden');

    return () => {
      document.body.classList.remove('overflowHidden');
    };
  }, [isDrawerBlock]);

  return (
    <div className={styles.jobItem} data-wide={isWide ? 'wide' : ''}>
      {showButtons && (
        <Radio
          value={isMainJob}
          onChange={changeMainJob}
          defaultStyle={'job'}
          options={[{ title: 'основное направление', value: true }]}
        />
      )}

      <div>
        <TextInput
          label={`Компания_${index}`}
          placeholder=' '
          invisible={invisible}
          value={job.place}
          onChange={handleChangeCompanyName}
          classN
          isArray
        />

        {error && !job.place && <Invalid>Заполните поле</Invalid>}
      </div>

      <div>
        {isTablet ? (
          <>
            <div onClick={() => setIsDrawerBlock(true)}>
              <Select
                components={{
                  ValueContainer: CustomValueContainer,
                  DropdownIndicator,
                }}
                menuPlacement='auto'
                styles={
                  // eslint-disable-next-line no-nested-ternary
                  (!selectedValue || !selectedValue?.length) && error
                    ? {
                        ...redesignSelectMultiStyles,
                        control: (base) => ({
                          ...base,
                          border: `none`,
                          borderRadius: '6px',
                          transition: '0.25s border-color ease-in-out !important',
                          minHeight: '56px !important',
                          padding: '2px 52px 2px 2px',
                          cursor: 'pointer',
                          boxShadow: 'unset !important',
                          '&:hover, &:focus': {
                            borderColor: '#0018a8 !important',
                          },
                          '&:active': {
                            borderColor: '#ec5d2a !important',
                          },
                        }),
                      }
                    : selectedValue.length < 3
                    ? redesignSelectMultiStyles
                    : {
                        ...redesignSelectMultiStyles,
                        menu: (base) => ({
                          ...base,
                          display: 'none !important',
                        }),
                      }
                }
                onMenuOpen={() => setIsDrawerBlock(true)}
                menuIsOpen={false}
                options={fields}
                placeholder='Сфера деятельности'
                isMulti
                isClearable={false}
                value={selectedValue}
                isDisabled
              />
            </div>

            <CSSTransition in={isDrawerBlock} nodeRef={drawerBlockRef} timeout={250} unmountOnExit>
              <div ref={drawerBlockRef}>
                <DrawerBlock
                  title='сфера деятельности'
                  subtitle={
                    job.fieldsIds.length !== 3
                      ? 'Вы можете указать три отрасли'
                      : 'Добавлено максимальное количество отраслей'
                  }
                  closeButton
                  onClose={closeClicked}
                  isRedesign
                >
                  <div>
                    {job.fieldsIds.length !== 3 && (
                      <Select
                        ref={inputRef}
                        styles={
                          job.fieldsIds.length < 3
                            ? {
                                ...setMobileOrTabletStyles(),
                                control: (base) => ({
                                  ...base,
                                  ...setMobileOrTabletStyles().control(),
                                  height: '38px',
                                }),
                              }
                            : {
                                ...setMobileOrTabletStyles(),
                                menu: (base) => ({
                                  ...base,
                                  display: 'none !important',
                                }),
                                control: (base) => ({
                                  ...base,
                                  ...setMobileOrTabletStyles().control(),
                                  height: '38px',
                                }),
                              }
                        }
                        options={fields}
                        onChange={handleChangeActivityFields}
                        components={{ Control: CustomControl, Menu: CustomMenu }}
                        placeholder='поиск'
                        controlShouldRenderValue={false}
                        isClearable={false}
                        isSearchable={job.fieldsIds.length < 3}
                        closeMenuOnSelect={true}
                        openMenuOnFocus
                        isMulti
                        value={selectedValue}
                        className={`${styles.react_select} react-select react-select--capital`}
                        classNamePrefix={'react-select react-select--capital'}
                        defaultMenuIsOpen={!fixValues(job.fieldsIds).length}
                      />
                    )}

                    <div
                      className={clsx(styles.values, {
                        [styles.values_full]: job.fieldsIds.length === 3,
                      })}
                    >
                      {fixValues(selectedValue).map((val, i) => {
                        return (
                          <div className={styles.value} key={i}>
                            {val?.label}
                            <div data-name={val?.label} onClick={handleRemoveValue}>
                              <Icon size='small' name='close'></Icon>
                            </div>
                          </div>
                        );
                      })}

                      <Button
                        className={styles.button}
                        block
                        height={'big'}
                        onClick={buttonClicked}
                      >
                        <p>Готово</p>
                      </Button>
                    </div>
                  </div>
                </DrawerBlock>
              </div>
            </CSSTransition>
          </>
        ) : (
          <>
            <Select
              components={{
                ValueContainer: CustomValueContainer,
                DropdownIndicator,
              }}
              placeholder='Сфера деятельности'
              menuPlacement='auto'
              styles={
                // eslint-disable-next-line no-nested-ternary
                (!selectedValue || !selectedValue?.length) && error
                  ? {
                      ...redesignSelectMultiStyles,
                      control: (base) => ({
                        ...base,
                        border: `none`,
                        borderRadius: '6px',
                        transition: '0.25s border-color ease-in-out !important',
                        minHeight: '56px !important',
                        padding: '2px 52px 2px 2px',
                        cursor: 'pointer',
                        boxShadow: 'unset !important',
                        '&:hover, &:focus': {
                          borderColor: '#0018a8 !important',
                        },
                        '&:active': {
                          borderColor: '#ec5d2a !important',
                        },
                      }),
                    }
                  : selectedValue.length < 3
                  ? redesignSelectMultiStyles
                  : {
                      ...redesignSelectMultiStyles,
                      menu: (base) => ({
                        ...base,
                        display: 'none !important',
                      }),
                    }
              }
              options={fields}
              onChange={handleChangeActivityFields}
              value={selectedValue}
              onMenuOpen={() => setIsOpen(true)}
              onMenuClose={() => setIsOpen(false)}
              isSearchable={selectedValue.length < 3}
              isClearable={false}
              isMulti
            />

            {selectedValue.length !== 3 ? (
              <div className={styles.activity}>Вы можете указать три отрасли</div>
            ) : (
              <div className={styles.activity}>Добавлено максимальное количество отраслей</div>
            )}
          </>
        )}

        {(!selectedValue || !selectedValue?.length) && error && <Invalid>Заполните поле</Invalid>}
      </div>

      <div>
        <TextInput
          label={`Должность_${index}`}
          placeholder=' '
          invisible={invisible}
          value={job.position}
          maxLength='100'
          classN
          isArray
          onChange={handleChangePosition}
        />
        {error && !job.position && <Invalid>Заполните поле</Invalid>}
      </div>

      {jobsLength === 1 ? (
        <>
          {!jError && (
            <strong className={styles.delete} onClick={clearJobField}>
              <Icon name='remove'></Icon>
            </strong>
          )}
        </>
      ) : (
        <>
          {showButtons && (
            <strong className={styles.delete} onClick={onDelete}>
              <Icon name='remove'></Icon>
            </strong>
          )}
        </>
      )}
    </div>
  );
};
