import { useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useMediaQuery } from '@mui/material';
import { CSSTransition } from 'react-transition-group';

import { Button } from 'components/core-ui/Button/Button';
import { DrawerBlock } from 'components/core-ui/DrawerBlock/DrawerBlock';

import styles from './InputPhone.module.scss';

export const InputPhone = ({ value, onChange }) => {
  const isTablet = useMediaQuery('(max-width: 1024px)');
  const drawerBlockRef = useRef(null);
  const [isDrawerBlock, setIsDrawerBlock] = useState(false);

  return isTablet ? (
    <div>
      <div onClick={() => setIsDrawerBlock(true)}>
        <PhoneInput
          country={'ru'}
          disabled={true}
          value={value}
          onChange={(data) => {
            onChange(data);
          }}
        />
      </div>

      <CSSTransition in={isDrawerBlock} nodeRef={drawerBlockRef} timeout={250} unmountOnExit>
        <div ref={drawerBlockRef}>
          <DrawerBlock
            title='Код страны'
            closeButton
            onClose={() => setIsDrawerBlock(false)}
            isRedesign
          >
            <PhoneInput
              country={'ru'}
              value={value}
              onChange={(data) => {
                onChange(data);
              }}
              showDropdown
            />

            <Button className={styles.submit} onClick={() => setIsDrawerBlock(false)}>
              Готово
            </Button>
          </DrawerBlock>
        </div>
      </CSSTransition>
    </div>
  ) : (
    <PhoneInput country={'ru'} value={value} onChange={onChange} />
  );
};
