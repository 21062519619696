import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Icon } from '../Icon/Icon';

import styles from './Checkbox.module.scss';

const Checkbox = ({ value, children, onChange, disabled, className }) => {
  const unchecked = <div className={styles.unchecked}></div>;
  const checked = (
    <div className={styles.checked}>
      <Icon name='checkboxChecked' />
    </div>
  );
  const intermediate = (
    <div className={styles.intermediate}>
      <Icon name='checkboxDash' />
    </div>
  );

  let icon = unchecked;

  if (value === true) {
    icon = checked;
  } else if (value === 1) {
    icon = intermediate;
  }

  const toggleValue = () => {
    if (disabled) return;
    if (value === true || value === 1) {
      return onChange(false);
    }
    onChange(true);
  };

  return (
    <div
      className={clsx(styles.checkbox, { [className]: className })}
      data-disabled={disabled ? 'disabled' : ''}
      onClick={toggleValue}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.title}>{children}</div>
    </div>
  );
};

Checkbox.propTypes = {
  value: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  value: false,
  onChange: () => {},
  disabled: false,
};

export { Checkbox };
