import { createSlice } from '@reduxjs/toolkit';
import axios from '@util/axios';
import SelectUsersResult from 'components/main/users/SelectUsersResult';

const recommendFormSlice = createSlice({
  name: 'recommendForm',
  initialState: {
    redommendations: [],
    isLoading: false,
    error: '',
  },
  reducers: {
    setNewRecommendation(state, action) {
      state = state.redommendations.push(action.payload);
    },

    reccomenationLoading(state) {
      state.isLoading = true;
    },
    reccomenationLoadingSuccess(state, action) {
      state.isLoading = false;
      state = state.redommendations.push(action.payload);
    },
    reccomenationLoadingError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export default recommendFormSlice;

export const createRecommedation = (id, data) => async (dispatch) => {
  try {
    dispatch(recommendFormSlice.actions.reccomenationLoading());
    const response = await axios.post(`/hrsm/vacancies/${id}/recommendations`, data);
    if (response.data.success === false) {
      dispatch(
        recommendFormSlice.actions.reccomenationLoadingError(JSON.stringify(response.data.errors)),
      );
    } else {
      dispatch(
        recommendFormSlice.actions.reccomenationLoadingSuccess(response.data.recommendation),
      );
    }
  } catch (error) {
    dispatch(recommendFormSlice.actions.reccomenationLoadingError(error.message));
  }
};

export const getRecommedations = (id) => async (dispatch) => {
  try {
    dispatch(recommendFormSlice.actions.reccomenationLoading());
    const response = await axios.get(`/hrsm/vacancies/${id}/recommendations`);
    if (response.data.success === false) {
      dispatch(
        recommendFormSlice.actions.reccomenationLoadingError(JSON.stringify(response.data.errors)),
      );
    } else {
      dispatch(
        recommendFormSlice.actions.reccomenationLoadingSuccess(response.data.recommendation),
      );
    }
  } catch (error) {
    dispatch(recommendFormSlice.actions.reccomenationLoadingError(error.message));
  }
};
