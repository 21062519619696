import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import commonSlice from '@store/common';

const LoaderToggler = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonSlice.actions.setGlobalLoading(true));
    return () => {
      setTimeout(() => {
        dispatch(commonSlice.actions.setGlobalLoading(false));
      }, 500);
    };
  }, []);
  return <div></div>;
};

export default LoaderToggler;
