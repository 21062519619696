import { createSlice } from '@reduxjs/toolkit';
import { io } from 'socket.io-client';

const socket = io(`${process.env.REACT_APP_API_URL.split('/api/')[0]}/messenger/`, {
  path: `/ws/`,
  withCredentials: true,
});

export const messengerSlice = createSlice({
  name: 'messenger',
  initialState: {
    dialogs: [],
    searchUsers: [],
    dialog: null,
    targets: [],
  },
  reducers: {
    setInitDone(state, action) {
      state.initDone = action.payload;
    },
    setTargets(state, action) {
      state.targets = action.payload;
    },
    setDialogs(state, action) {
      state.dialogs = action.payload;
    },
    dialogUpdated(state, action) {
      const dialog = action.payload;
      if (!dialog) return;
      const existingDialog = state.dialogs.find((d) => d.id === dialog.id);
      if (existingDialog) {
        Object.assign(existingDialog, dialog);
      } else {
        state.dialogs.push(dialog);
      }
    },
    setDialog(state, action) {
      state.dialog = action.payload;
    },
    clearDialog(state) {
      state.dialog = null;
    },
    dialogStatusUpdated(state, action) {
      const status = action.payload;
      if (!state.dialog || state.dialog.id !== status.dialogId) return;
      state.dialog.isAccepted = status.isAccepted;
      state.dialog.isDeclined = status.isDeclined;
    },
    messageUpdated(state, action) {
      const message = action.payload;
      if (!state.dialog || state.dialog.id !== message.dialog.id) return;
      const existingMessage = state.dialog.messages.find((m) => m.id === message.id);
      if (existingMessage) {
        Object.assign(existingMessage, message);
      } else {
        state.dialog.messages.push(message);
      }
    },
  },
});

export const setTargets = (targets) => (dispatch) => {
  dispatch(messengerSlice.actions.setTargets(targets));
};

export const updateDialog = (dialog) => (dispatch, getState) => {
  const state = getState();
  const isDialogActive = state.messenger.dialog && state.messenger.dialog.id === dialog.id;
  if (isDialogActive && dialog.unreadCount > 0) {
    socket.emit('read-dialog', dialog.id);
  } else {
    dispatch(messengerSlice.actions.dialogUpdated(dialog));
  }
};

export const initMessenger = () => (dispatch, getState) => {
  socket.emit('get-dialogs', (dialogs) => {
    dispatch(messengerSlice.actions.setDialogs(dialogs));
  });
  socket.on('dialog-updated', (dialog) => {
    dispatch(updateDialog(dialog));
  });
  socket.on('dialog-status-updated', (status) => {
    dispatch(messengerSlice.actions.dialogStatusUpdated(status));
  });
  socket.on('message-updated', (message) => {
    dispatch(messengerSlice.actions.messageUpdated(message));
  });
};

export const sendDialogRequests = (data) => (dispatch) => {
  socket.emit('send-dialog-requests', data);
};

export const fetchDialog = (dialogId) => (dispatch) => {
  socket.emit('get-dialog-with-messages', dialogId, (dialog) => {
    dispatch(messengerSlice.actions.setDialog(dialog));
  });
};

export const declineRequest = (dialogId) => (dispatch) => {
  socket.emit('decline-request', dialogId);
};

export const acceptRequest = (dialogId) => (dispatch) => {
  socket.emit('accept-request', dialogId);
};

export const sendMessage = (data) => (dispatch) => {
  socket.emit('send-message', data);
};

export const readMessage = (messageId) => (dispatch) => {
  socket.emit('read-message', messageId);
};

export const exitDialog = () => (dispatch) => {
  dispatch(messengerSlice.actions.setDialog(null));
};

export default messengerSlice;
