import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { fetchProfile, removeAvatar, uploadImage } from 'store/profile';
import commonSlice from 'store/common';
import completionSlice from 'store/completion';
import { completeRegistrationPersonal } from 'store/registration';

import { Button } from 'components/core-ui/Button/Button';
import { Checkbox } from 'components/core-ui/Checkbox/Checkbox';
import { Invalid } from 'components/core-ui/Invalid/Invalid';
import { Popover } from 'components/core-ui/Popover/Popover';
import { TextInput } from 'components/core-ui/TextInput/TextInput';
import { CityInput } from 'components/common/education/CityInput';
import { InputPhone } from 'components/common/education/InputPhone/InputPhone';
import { RedesignImagePicker } from 'components/common/image-picker/RedesignImagePicker';
import Loader from 'components/common/Loader';

import 'react-phone-input-2/lib/style.css';
import styles from './ContactsStep.module.scss';

export const ContactsStep = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);
  const loading = useSelector((state) => state.common.loading);
  const [accepted, setAccepted] = useState(false);

  const formatProfilePhone = (phoneValue) => {
    let newPhone;

    if (phoneValue[0] === '8') newPhone = `7${phoneValue.slice(1)}`;
    else newPhone = phoneValue;

    return newPhone;
  };

  const initialData = {
    firstName: profile ? profile.firstName : '',
    lastName: profile ? profile.lastName : '',
    phone: profile ? formatProfilePhone(profile.phone) : '',
    email: profile ? profile.email : '',
    city: profile ? profile.city : '',
  };

  const imageChanged = async (blob) => {
    dispatch(uploadImage(blob));
  };

  const removePicture = () => {
    dispatch(removeAvatar());
  };

  const formSubmitted = async (data) => {
    dispatch(completionSlice.actions.changeRedesignStep(3));
    dispatch(commonSlice.actions.setLoading(true));
    setTimeout(() => {
      dispatch(completeRegistrationPersonal(data));
      window.scrollTo(0, 0);
    }, 300);
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
    isValid,
  } = useFormik({
    initialValues: initialData,
    onSubmit: formSubmitted,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors = {};
      if (values) {
        if (!values.firstName) {
          errors.firstName = 'Введите имя';
        } else if (!/[а-яА-ЯёЁ0-9\s]+$/.test(values.firstName)) {
          errors.firstName = 'В написании имени используйте кириллицу';
        }
        if (!values.lastName) {
          errors.lastName = 'Введите фамилию';
        } else if (!/[а-яА-ЯёЁ0-9\s]+$/.test(values.lastName)) {
          errors.lastName = 'В написании фамилии используйте кириллицу';
        }
        if (!values.phone) {
          errors.phone = 'Введите телефон';
        } else if (values.phone.length < 11) {
          errors.phone = 'Телефон введен некорректно';
        }
        if (!values.email) {
          errors.email = 'Введите электронный адрес';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Введен некорректный адрес';
        }
        if (!values.city) {
          errors.city = 'Выберите город';
        }
      }

      return errors;
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!profile) dispatch(fetchProfile());
  }, [profile, dispatch]);

  if (loading) {
    return (
      <div className={styles.content_loader}>
        <Loader isRegister />;
      </div>
    );
  }

  return (
    <div className={styles.content}>
      <div className={styles.photo}>
        <RedesignImagePicker onChange={imageChanged} onRemove={removePicture} />
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div>
          <TextInput
            label='Имя'
            placeholder=' '
            type='text'
            name='firstName'
            onChange={(e) => {
              if (errors.firstName) setFieldError('firstName', undefined);
              else handleChange(e);
            }}
            onBlur={handleBlur}
            value={values.firstName ? values.firstName : ''}
            error={errors.firstName ? errors.firstName : null}
            autoComplete='off'
            capital={true}
            classN
            isEmptyPlaceholder
          />
          {errors.firstName && touched.firstName && <Invalid>{errors.firstName}</Invalid>}
        </div>

        <div>
          <TextInput
            label='Фамилия'
            placeholder=' '
            type='text'
            name='lastName'
            onChange={(e) => {
              if (errors.lastName) setFieldError('lastName', undefined);
              else handleChange(e);
            }}
            onBlur={handleBlur}
            value={values.lastName}
            error={errors.lastName ? errors.lastName : null}
            autoComplete='off'
            capital={true}
            classN
            isEmptyPlaceholder
          />
          {errors.lastName && touched.lastName && <Invalid>{errors.lastName}</Invalid>}
        </div>

        <div>
          <CityInput
            name='city'
            placeholderDrawer='Город проживания'
            placeholder='город проживания'
            value={values.city}
            onChange={(city) => {
              setFieldValue('city', city);
            }}
            isRegister
            onBlur={handleBlur}
          />

          {errors.city && touched.city && <Invalid>{errors.city}</Invalid>}
        </div>

        <div>
          <TextInput
            label='Почта'
            placeholder=' '
            name='email'
            onChange={(e) => {
              if (errors.email) setFieldError('email', undefined);
              handleChange(e);
            }}
            onBlur={handleBlur}
            value={values.email ? values.email : ''}
            error={errors.email ? errors.email : null}
            autoComplete='off'
            capital={true}
            classN
            isEmptyPlaceholder
          />
          {errors.email && touched.email && <Invalid>{errors.email}</Invalid>}
        </div>

        <div>
          <InputPhone
            value={values.phone}
            onChange={(e) => {
              if (e !== undefined || e !== null || !isNaN(e)) {
                setFieldValue('phone', e);
              }
            }}
          />
          {errors.phone && touched.phone && <Invalid>{errors.phone}</Invalid>}
        </div>

        <div className={styles.policy}>
          <Checkbox value={accepted} onChange={(value) => setAccepted(value)} />

          <p onClick={() => setAccepted((prev) => !prev)}>
            Я ознакомлен с{' '}
            <a href='/docs/Politika_konfidencialnosti_Liderport.pdf' target='_blank'>
              политикой конфеденциальности
            </a>{' '}
            и{' '}
            <a href='/docs/Soglasie_na_obrabotku_personalnyh_dannyh_Liderport.pdf' target='_blank'>
              согласен на обработку персональных данных{' '}
            </a>
          </p>
        </div>

        <div className={styles.footer}>
          <Popover
            isActive={!accepted || !isValid || !values.city}
            isAlignTop
            tooltip='Для продолжения необходимо заполнить все поля'
          >
            <Button
              width='normal'
              type='submit'
              className={styles.submit}
              disabled={!accepted || !isValid || !values.city}
            >
              Далее
            </Button>
          </Popover>
        </div>
      </form>
    </div>
  );
};
