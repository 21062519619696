import { createSlice } from '@reduxjs/toolkit';

import axios from '@util/axios';

export const clubSlice = createSlice({
  name: 'club',
  initialState: {
    savedFilters: null,
    learnStep: null,
    filters: {
      cities: [],
      fields: [],
      competences: [],
      lobbies: [],
    },
  },
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setSavedFilters(state, action) {
      state.savedFilters = { [action.payload.page]: action.payload.filters };
    },
    setLearnStep(state, action) {
      state.learnStep = action.payload;
    },
    clearLearnStep(state) {
      state.learnStep = null;
    },
  },
});

export const fetchFilters = (comId) => async (dispatch, getState) => {
  const state = getState();
  if (state.club.filters.fields.length) return;
  const response = await axios.get('/club/filters/', {
    params: {
      comId,
    },
  });
  dispatch(clubSlice.actions.setFilters(response.data.filters));
};

export default clubSlice;
