import { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';

import urls from 'settings/urls.yaml';
import datetime from 'util/datetime';

import { restoreUser } from 'store/auth';

import { Button } from 'components/core-ui/Button/Button';
import { Icon } from 'components/core-ui/Icon/Icon';

import styles from './Removed.module.scss';

const Removed = () => {
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:600px)');
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dateTimeText, setDateTimeText] = useState('');
  const [restoreText, setRestoreText] = useState('');

  const restoreAccount = () => {
    dispatch(restoreUser());
    navigate(urls.main.base);
  };

  useEffect(() => {
    if (user && user.removed) {
      if (isMobile) {
        setDateTimeText(
          `Вы можете восстановить\nсвой аккаунт до ${datetime.formatRemoveDate(
            user.removed.createdAt,
          )}`,
        );
        setRestoreText(
          'Мы моментально восстановим ваши данные и вы сможете продолжить пользоваться сервисами\nнашей платформы',
        );
      } else if (isTablet) {
        setDateTimeText(
          `Вы можете восстановить свой аккаунт \nдо ${datetime.formatRemoveDate(
            user.removed.createdAt,
          )}`,
        );
        setRestoreText(
          'Мы моментально восстановим ваши данные\nи вы сможете продолжить пользоваться сервисами\nнашей платформы',
        );
      } else {
        setDateTimeText(
          `Вы можете восстановить свой аккаунт до ${datetime.formatRemoveDate(
            user.removed.createdAt,
          )}`,
        );
        setRestoreText(
          'Мы моментально восстановим ваши данные и вы сможете продолжить пользоваться сервисами нашей платформы',
        );
      }
    }
  }, [isMobile, isTablet, user]);

  if (!user.removed) {
    return <Navigate to={urls.main.base} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Icon name='logo_wite' />
      </div>

      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>Аккаунт удален</div>

          <Link to={urls.landing.base} className={styles.close}>
            <Icon name={isTablet ? 'closeMobile' : 'close'} />
          </Link>
        </div>

        <div className={styles.list}>
          <div className={styles.body}>
            <div className={styles.icon}>
              <Icon name='stop' />
            </div>

            <div className={styles.description}>
              <div className={styles.description_dateTime}>
                {dateTimeText.split('\n').map((text, i) => (
                  <span key={`dateTime_${i}`}>{text}</span>
                ))}
              </div>
              <div className={styles.description_text}>
                {restoreText.split('\n').map((text, i) => (
                  <span key={`restore_${i}`}>{text}</span>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.buttons}>
            <Button onClick={() => navigate('/')} className={styles.buttons_goSite}>
              Перейти на сайт
            </Button>

            <Button onClick={restoreAccount} className={styles.buttons_restore}>
              Восстановить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Removed;
