import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { clsx } from 'clsx';

import commonSlice from 'store/common';
import completionSlice from 'store/completion';
import registrationSlice, {
  completeRegistrationPersonal,
  completeRegistrationSendCode,
} from 'store/registration';

import { Button } from 'components/core-ui/Button/Button';
import { InputPhone } from 'components/common/education/InputPhone/InputPhone';
import { Modal } from 'components/core-ui/Modal/Modal';

import styles from './ComfirmPhoneModal.module.scss';

export const ConfirmPhoneModal = (props) => {
  const { isEdit, title, closeModal } = props;

  const dispatch = useDispatch();
  const isTablet = useMediaQuery('(max-width: 1024px)');
  const { profile } = useSelector((state) => state.profile);
  const [phoneValue, setPhoneValue] = useState('');

  const changeProfilePhone = () => {
    dispatch(
      completeRegistrationPersonal({
        firstName: profile.firstName,
        lastName: profile.lastName,
        city: profile.city,
        email: profile.email,
        phone: phoneValue,
      }),
    );
    dispatch(commonSlice.actions.setLoading(true));
    dispatch(completionSlice.actions.changeRedesignStep(4));
    dispatch(registrationSlice.actions.setIsEditPhone(true));
    dispatch(completeRegistrationSendCode());
    closeModal();
  };

  useEffect(() => {
    if (profile) setPhoneValue(profile.phone);
  }, [profile]);

  return (
    <Modal
      onClose={closeModal}
      contentClassName={clsx(styles.modalContent, { [styles.modalContent_edit]: isEdit })}
      closeIconClassName={styles.modalClose}
      isRedesign
      iconPosition
    >
      <div className={styles.content}>
        <h2>{title}</h2>

        {!isEdit ? (
          <div className={styles.body}>
            <div className={styles.phone}>
              <span className={styles.phone_title}>Это ваш номер?</span>
              <span className={styles.phone_number}>{profile && profile.phone}</span>
            </div>

            <div className={styles.footer}>
              <Button
                onClick={() => dispatch(registrationSlice.actions.setIsEditPhone(true))}
                className={styles.footer_cancel}
              >
                Нет
              </Button>

              <Button onClick={changeProfilePhone} className={styles.footer_submit}>
                Да
              </Button>
            </div>
          </div>
        ) : (
          <div className={clsx(styles.body, { [styles.body_edit]: isEdit })}>
            <div className={styles.phone}>
              <div className={styles.phone_new}>
                {isTablet ? (
                  <InputPhone value={phoneValue} onChange={setPhoneValue} />
                ) : (
                  <PhoneInput
                    inputClass={styles.phone_input}
                    country={'ru'}
                    onChange={(e) => {
                      setPhoneValue(e);
                    }}
                    value={phoneValue}
                  />
                )}
              </div>
            </div>

            <div className={clsx(styles.footer, { [styles.footer_edit]: isEdit })}>
              <Button onClick={changeProfilePhone} className={styles.footer_changePhone}>
                Изменить и подтвердить
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
